import * as React from "react";

import { MenuHeader } from "../Atomics";
import { CSpinner } from "@coreui/react";
import "./RoleList.scss";
import { removeContactFromParents } from "../../services/rolesService";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import "./RoleList.scss"
// interfaces
import { RolesUser } from "../types";
import RolesRow from "./RolesRow";
import { cilPeople } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

interface Props {
  parents: Array<RolesUser>,
  loading: boolean,
  refreshParents: () => void
}

const RolesList: React.FC<Props> = ({ parents, loading, refreshParents }) => {
  async function removeUser(parentId: number): Promise<void> {
    try {
      await toast.promise(removeContactFromParents(parentId), {
        loading: "Removing role...",
        success: "role removed from organization",
        error: "Error removing role from organization",
      });
      await refreshParents();
    } catch (e) {
      HandleUIError(e);
    }
  }

  function onPressRemoveUser(parentId: number) {
    const confirmationDialogOptions = {
      title:
        "Are you sure you want to remove this?",
      confirmAction: () => removeUser(parentId),
    };
    confirmationDialog(confirmationDialogOptions);
  }

  return (
    <>
      <div className="" style={{ overflowX: "auto" }}>


        <MenuHeader className="d-flex pt-3 pb-3 justify-content-between  table-size">
          <CIcon
            icon={cilPeople}
            size="xl"
            className="staff-icon section-width-1"
          />
          <p className="text-color w-13 d-none d-sm-block">User</p>
          <p className="text-color section-width-parents-1 d-none d-sm-block">Email</p>
          <p className="text-color  d-none d-sm-block w-13">Phone</p>
          <p className="text-color  d-none d-sm-block w-13">Last Connected</p>
          <p className="text-color  d-none d-sm-block w-13">Registered At</p>
          <div className="d-flex justify-content-end w-13 align-items-center">
            <div className="p-1">
              <button
                className="btn btn-sm btn-primary"
                style={{ visibility: "hidden" }}
              >
                View Logs
              </button>
            </div>
          </div>
        </MenuHeader>
        {!loading ? (
          <>
            {parents?.length > 0 ? (
              parents.map((element: RolesUser, index: number) => {
                return (
                  <>
                    <RolesRow
                      parent={element}
                      key={index}
                      removeUser={onPressRemoveUser}
                      refreshParents={refreshParents}
                    />
                  </>
                );
              })
            ) : (
              <div className="d-flex justify-content-center">
                <p className="text-light fs-5 mt-4">No data to show</p>
              </div>
            )}
          </>
        ) : (
          <div className="spinner">
            <CSpinner variant="grow" />
          </div>
        )}
      </div>

    </>
  );
};

export default RolesList;