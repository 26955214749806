import * as React from "react";

import { MenuHeader } from "../Atomics";
import { CSpinner } from "@coreui/react";
import "./EmergencyList.scss";
import { removeEmergency } from "../../services/emergencyService";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import "./EmergencyList.scss";
// interfaces
import { Roles } from "../types";
import EmergencyRow from "./EmergencyRow";
import { createActivityReq } from "../../services/activityLogService";
import EmergencyRowRelation from "./EmergencyRowRelation";
import { removeEmergencyRelation } from "../../services/emergencyRelationService";
import { Link, useHistory } from "react-router-dom";
import { FaList } from "react-icons/fa";
import moment from "moment";
import FwdRowActivities from "./FwdRowActivities";

interface Props {
  parents: any;
  loading: boolean;
  refreshParents: () => void;
}

const FwdActivityList: React.FC<Props> = ({
  parents,
  loading,
  refreshParents,
}) => {
  async function removeUser(parentId: number): Promise<void> {
    try {
      await toast.promise(removeEmergencyRelation(parentId), {
        loading: "Removing Emergency Relation Contact...",
        success: "Emergency Contact Relation removed from organization",
        error: "Error removing Emergency Relation Contact from organization",
      });
      await refreshParents();
      await createActivityReq('Emergency Contact Removed Successfully')
    } catch (e) {
      HandleUIError(e);
    }
  }

  function onPressRemoveUser(parentId: number) {
    const confirmationDialogOptions = {
      title: "Are you sure you want to remove this?",
      confirmAction: () => removeUser(parentId),
    };
    confirmationDialog(confirmationDialogOptions);
  }
  const history = useHistory();

  const handleNavigation = (item: any) => {
    history.push('/alert-activity-count', item);
  };


  console.log("firstparentsparents", parents)
  return (
    <>
      <MenuHeader className=" justify-content-around">
        <div className="d-flex border-0 py-3 px-3 user-information-container justify-content-around">

          <div className="col d-flex justify-content-start" >
            <p className="text-color word-break" style={{ width: "100%" }}>
              Alert Name
            </p>
          </div>
          <div className="col d-flex justify-content-start" >
            <p className="text-color word-break" style={{ width: "100%" }}>
              Roles
            </p>
          </div>
          <div className="col d-flex " >
            <p className="text-color word-break" style={{ width: "100%" }}>
              Total Students
            </p>
          </div>


          <div className="col d-flex " >
            <p className="text-color word-break" style={{ width: "100%" }}>
              Acknowledgement
            </p>
          </div>

          <div className="col d-flex " >
            <p className="text-color word-break" style={{ width: "100%" }}>
              Date
            </p>
          </div>

          <div className="col d-flex " >
            <p className="text-color word-break" style={{ width: "100%" }}>
              Status
            </p>
          </div>

          <div className="col-auto d-flex " style={{ visibility: "hidden" }}>
            <Link to='student-list' className="text-light word-break" style={{ width: "100%" }}>
              <FaList />
            </Link>
          </div>


        </div>
      </MenuHeader>
      {!loading ? (
        parents?.map((element: Roles, index: number) => {
          return (
            <>
              <FwdRowActivities
                parent={element}
                key={index}
                removeUser={onPressRemoveUser}
                refreshParents={refreshParents}
              />
            </>
          );
        })
      ) : (
        <div className="spinner">
          <CSpinner variant="grow" />
        </div>
      )}
    </>
  );
};

export default React.memo(FwdActivityList);
