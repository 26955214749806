import * as React from "react";
import "../../views/Staff.scss";

import { cilEyedropper, cilPencil, cilTrash, cilUser } from "@coreui/icons";

import { IconButton } from "../Atomics";
import { Roles } from "../types";
import EditRole from "./EditRole";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { convertUTCtoLocale } from "../../utils/formatConverters";
import moment from "moment";
// import { saveRolesUser } from "../../redux/reducers/userReducer";
//service




interface ParentsRowProps {
  parent: Roles;
  removeUser: (id: number) => void;
  refreshParents: () => void;
  setIsEdit: (data: any) => void;
}
interface LocationState {
  id: string;
}


const GroupRow: React.FC<ParentsRowProps> = ({
  parent,
  removeUser,
  refreshParents,
  setIsEdit,
}) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [visibleEditParent, setVisibleEditParent] = React.useState(false);
  const localeDate = convertUTCtoLocale(parent.createdAt);

  function onPressAdd() {
    setVisibleEditParent(!visibleEditParent);
  }


  const location = useLocation<LocationState>();
  const id = location.state?.id;

  React.useEffect(() => {
    console.log("location id:", id);
    console.log("parent id:", parent.id);
    if (id && id === parent.id.toString()) {
      setVisibleEditParent(true);
    }
  }, [id, parent.id]);

  return (
    <>
      <div className="d-flex py-3 user-information-container justify-content-around px-2 table-size">

        <div className="section-width-parents-custom d-flex justify-content-start ">
          <p className="text-light word-break">
            {parent?.student ? parent?.student?.firstName : parent?.user?.firstName} {parent?.student ? parent?.student?.lastName : parent?.user?.lastName}
          </p>
        </div>

        <div className="section-width-parents-custom d-flex justify-content-start ">
          <p className="text-light word-break">{parent?.group?.name ? parent?.group?.name : parent?.alert_name}</p>
        </div>

        <div className="section-width-parents-custom d-flex justify-content-start">
          <p
            className="text-light word-break"
            role="button"
            onClick={() => onPressAdd()}
          >
            {(parent?.type ? parent?.type : parent?.alert_type) || parent?.alert_name}
          </p>
        </div>


        {parent?.zone && (
          <div className="section-width-parents-custom d-flex justify-content-start ">
            <p
              className="text-light word-break"
              onClick={() => setIsEdit(parent)}
              role="button"
            >
              {parent?.zone ? parent?.zone?.name : ''}
            </p>
          </div>
        )}
        <div className="section-width-parents-custom d-flex justify-content-start ">
          <p className="text-light word-break">{parent?.student ? parent?.student?.role?.role_name : parent?.user?.role?.role_name}</p>
        </div>
        <div className="section-width-parents-custom d-flex justify-content-start ">
          <p className="text-light word-break">
            {moment(localeDate).format("L")} At {moment(localeDate).format("LT")}
          </p>
        </div>

        <div className="w-auto d-flex justify-content-end align-items-center">
          <div className="margin-icon">
            <span
              className="badge bg-primary text-white"
              role="button"
              onClick={() => onPressAdd()}
            >
              View
            </span>
          </div>
          <div className="d-none">
            <IconButton
              icon={cilTrash}
              size="lg"
              className="ms-1"
              onClick={() => removeUser(parent.id)}
            />
          </div>
        </div>
      </div>
      <EditRole
        visible={visibleEditParent}
        closeModal={onPressAdd}
        refreshParents={refreshParents}
        parentData={{
          parentId: parent.id,
          type: parent?.type ? parent?.type : parent?.alert_type,
          fisrt_name: parent?.student ? parent?.student?.firstName : parent?.user?.firstName,
          last_name: parent?.student ? parent?.student?.lastName : parent?.user?.lastName,
          group_name: parent?.group?.name ? parent?.group?.name : parent?.alert_name,
          message: parent.message,
        }}
      />
    </>
  );
};

export default React.memo(GroupRow);
