import * as React from "react";
import "./Login.scss";
import { Input, Button, Spinner } from "../../components/Atomics";
import { authenticate, getOrganizationData } from "../../services/SecurityService";
import { inputEvent } from "../../utils/types";
import { validateEmail } from "../../helpers/validations";
import HandleUIError from "../../utils/HandleUIError";
import ApiErrorStatusCode from "../../utils/ApiErrorStatusCode";

// images
import gcLogoSq from "../../assets/gc_logo_square_white.svg";
import { Link } from "react-router-dom";
import { OrganizationInformation } from "../../services/types";

const Login: React.FC = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const [searchOrganization, setSearchOrganization] = React.useState("");
  const [organizationName, setOrganizationName] = React.useState("");
  const [organizationID, setOrganizationId] = React.useState("");
  const [allOrganization, setAllOrganization] = React.useState([]);
  const [organizationLoader, setOrganizationLoader] = React.useState(false);
  const [organizationDropdown, setOrganizationDropdown] = React.useState(false);

  const [showLogin, setShowLogin] = React.useState(false);



  function changeEmail(e: inputEvent) {
    setEmail(e.currentTarget.value);
  }

  function changePassword(e: inputEvent) {
    setPassword(e.currentTarget.value);
  }

  function onPressSignIn(e: React.FormEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("Email is not valid");
      return;
    }
    signIn();
  }

  function errorManagement(errorCode: number) {
    if (errorCode === ApiErrorStatusCode.AuthenticationError) {
      setError("Invalid email or password");
    }
  }

  function errorManagementCustom(errorMessage: string) {
    setError(errorMessage);
  }

  async function signIn() {
    setLoading(true);
    /*await authenticate({ email, password })
        .then((res) => {
          console.log('res', res)
        })
        .catch((err) => {
          console.log('err', err)
        })
        .finally(() => {
            setLoading(false);
        })*/
    try {
      setLoading(true);
      const res: any = await authenticate({ email, password, organization_id: organizationID });
      if (typeof res == 'string') {
        errorManagementCustom(res);
      }
      setLoading(false);
    } catch (e: any) {
      HandleUIError(e, errorManagement);
      setLoading(false);
    }
  }

  const reFetchOrganization = (id: string) => {
    setOrganizationLoader(true)
    getOrganizationData(id)
      .then((res: any) => {
        setAllOrganization(res)
        console.log("first res", res)
      })
      .catch((e) => {
        HandleUIError(e);
      })
      .finally(() => {
        setOrganizationLoader(false)
      })
  }

  const hanldeSearchedOrganization = (e: any) => {
    setOrganizationName(e.target.value);
    if (e.target.value.length >= 3) {
      setOrganizationDropdown(true)
      reFetchOrganization(e.target.value)
    } else {
      setOrganizationDropdown(false)
    }
  };


  const handleOrganization = (item: any) => {
    setOrganizationId(item?.id)
    setOrganizationName(item?.name)
    setOrganizationDropdown(false)
    setAllOrganization([])
  }




  return (
    <>

      {!showLogin && (

        <div className="login-container">
          <div className="login-border p-4">
            <form className="">
              <h1 className="text-center mb-4 text-color login-title">
                <img src={gcLogoSq} alt="GuardianCall" className="img-fluid" height={50} />
              </h1>
              <div className="">
                <p className="text-light mb-2">Search Organization</p>
                <Input
                  value={organizationName}
                  type="text"
                  onChange={hanldeSearchedOrganization}
                  className="w-100 login-input"
                />
              </div>

              {
                organizationDropdown && (
                  <>

                    <div className="border rounded overflow-auto mb-3" style={{ height: "150px" }}>
                      <ul className="ps-0" style={{ listStyleType: "none" }}>
                        {Array.isArray(allOrganization) && allOrganization?.map((item: OrganizationInformation, i) => {
                          return (
                            <>
                              <li onClick={() => handleOrganization(item)} className="text-light py-2 li-hover ">
                                <p className="ps-3">
                                  {item?.name}
                                </p>
                              </li>
                            </>
                          )
                        })}


                      </ul>
                    </div>
                  </>
                )
              }


              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setShowLogin(true);
                }}
                className="w-100 fs-6 sign-in-button mt-3"
                disabled={!organizationID}
              >
                {loading ? <Spinner className="ms-3" /> : "Next"}
              </Button>

            </form>
          </div>
        </div>

      )}

      {
        showLogin && (
          <div className="login-container">
            <div className="login-border p-4">
              <form className="">
                <h1 className="text-center mb-4 text-color login-title">
                  <img src={gcLogoSq} alt="GuardianCall" className="img-fluid" height={50} />
                </h1>
                <div className="mb-3">
                  <p className="text-light mb-2">Organization Name</p>
                  <Input
                    type="text"
                    value={organizationName}
                    disabled={true}
                    className="w-100 login-input"
                  />
                </div>
                <div className="mb-3">
                  <p className="text-light mb-2">Enter your email</p>
                  <Input
                    type="email"
                    onChange={changeEmail}
                    className="w-100 login-input"
                  />
                </div>
                <div className="mb-5">
                  <p className="text-light mb-2">Enter your password</p>
                  <Input
                    onChange={changePassword}
                    type="password"
                    className="w-100 login-input"
                  />
                  {error && <p className="text-danger">{error}</p>}
                </div>
                <div className="mb-3 text-end">
                  <Link to={"/verify"} className="text-light ">Forgot password ?</Link>
                </div>
                <Button
                  onClick={onPressSignIn}
                  className="w-100 fs-6 sign-in-button"
                  disabled={loading}
                >
                  {loading ? <Spinner className="ms-3" /> : "Sign In"}
                </Button>

                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowLogin(false);
                  }}
                  className="w-100 fs-6 sign-in-button mt-3"
                  disabled={loading}
                >
                  {loading ? <Spinner className="ms-3" /> : "Change Organization"}
                </Button>

              </form>
            </div>
          </div>
        )
      }

    </>
  );
};

export default Login;
