import * as React from "react";
import "./StaffNumbers.scss";
import { getTotalStaffDevices, getUserStatistics } from "../../services/staffService";
import HandleUIError from "../../utils/HandleUIError";
import { CSpinner } from "@coreui/react";
import { useEffect, useState } from "react";

const StaffNumbers: React.FC = () => {
  const [data, setData] = useState<any>(null)
  const [totalStaffNumber, setTotalStaffNumber] = React.useState<number>();
  const [inFacilityNumber, setInFacilityNumber] = React.useState<number>();
  const [offFacilityNumber, setOffFacilityNumber] = React.useState<number>();
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(function () {
    getTotalStaffNumber();
  }, []);

  async function getTotalStaffNumber() {
    setIsLoading(true);
    try {
      const res = await getTotalStaffDevices();
      setTotalStaffNumber(res.devices.total);
      setInFacilityNumber(res.devices.inFacility);
      setOffFacilityNumber(res.devices.outFacility);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      HandleUIError(e);
    }
  }

  const fetch = async () => {
    const res: any = await getUserStatistics();
    console.log('res==|', res)
    setData(res)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetch();
    }, 7000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="row g-2 justify-content-between">
        <div className="d-flex flex-column align-items-center text-center pb-3 justify-content-center  staffNumberContainer bg-totalStaff mb-3 mb-sm-0">
          <p className="text-light StaffNumbers">
            {!isLoading ? (
              <>
                {data?.off_site || 'None'}
                {/*{offFacilityNumber ? offFacilityNumber : "None"}*/}
              </>
            ) : (
              <CSpinner variant="grow" className="number-staff-spinner" />
            )}
          </p>
          <p className="text-light StaffNumbersLabel fs-6 text-center">Users Off-Site</p>
        </div>
        <div className="d-flex flex-column align-items-center pb-3 justify-content-center  staffNumberContainer bg-OnSiteStaff mb-3 mb-sm-0">
          <p className="text-light StaffNumbers">
            {!isLoading ? (
              <>
                {data?.on_site || 'None'}
                {/*{inFacilityNumber ? inFacilityNumber : "None"}*/}
              </>
            ) : (
              <CSpinner variant="grow" className="number-staff-spinner" />
            )}
          </p>
          <p className="text-light StaffNumbersLabel fs-6">Users On-Site</p>
        </div>
        <div className="d-flex flex-column align-items-center pb-3 justify-content-center  staffNumberContainer bg-OffSiteStaff mb-3 mb-sm-0">
          <p className="text-light StaffNumbers">
            {!isLoading ? (
              <>{data?.alert_count || "-"}</>
            ) : (
              <CSpinner variant="grow" className="number-staff-spinner" />
            )}
          </p>
          <p className="text-light StaffNumbersLabel fs-6">Users Alerts</p>
        </div>
      </div>
    </>
  );
};
export default StaffNumbers;
