import axiosConfig from "./axiosConfig";
import { AxiosError, AxiosResponse } from "axios";
import ApiError from "../utils/ApiError";
import { Roles } from "../components/types";

interface PaginationResponse {
  data: Array<Roles>;
  numberOfTotalParents: number;
}


export async function getActivityReq(StaffId: number): Promise<Array<Roles>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `/admin/activity-logs${StaffId ? `/${StaffId}` : ''}`
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function createActivityReq(message: any): Promise<Array<Roles>> {
  try {
    const response = await axiosConfig.post<AxiosResponse>(
      `/admin/activity-logs`,
      { description: message },
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}


// export async function fwdActivityReq(): Promise<Array<Roles>> {
//   try {
//     const response = await axiosConfig.get<AxiosResponse>(
//       `/admin/alert-activities`
//     );
//     return response.data.data as Array<any>;
//   } catch (e) {
//     const err = e as AxiosError | Error;
//     throw new ApiError(err);
//   }
// }

export async function fwdActivityReq(
  limit: number,
  page: number,
  order: string,
  title: string,
  type: string
): Promise<Array<any>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(`/admin/${type}-activities?limit=${limit}&page=${page}&order=${order}&title=${title}`);
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

// export async function getParents(
//   limit: number,
//   page: number
// ): Promise<Array<Parents>> {
//   try {
//     const response = await axiosConfig.get<AxiosResponse>(`/admin/contacts?limit=${limit}&page=${page}`);
//     return response.data.data as Array<Parents>;
//   } catch (e) {
//     const err = e as AxiosError | Error;
//     throw new ApiError(err);
//   }
// }
