
import * as React from "react";
import "../../views/Alerts.scss";

//components
import { Divider, Modal, IconButton, Button, Spinner } from "../Atomics";
import { CFormCheck } from "@coreui/react";
import { useHistory } from "react-router-dom";

//services
import { AlertTypeResponse } from "../../services/types";
import {
  setAlertDefaultById,
  deleteAlertType, deleteAlertCategory,
} from "../../services/alertServices";

//icons
import FacebookIcon from "../../assets/fb_icon.svg";
import TwitterIcon from "../../assets/tw_icon.svg";
import InstagramIcon from "../../assets/ig_icon.svg";
import HandleUIError from "../../utils/HandleUIError";
import { cilX, cilSettings, cilPen, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import usePermission from "../../hooks/usePermission";
import { createActivityReq } from "../../services/activityLogService";
import { useAppSelector } from "../../redux/hooks";

interface Props {
  showModal: (alertId: string, name: string, type: any, openedFrom: number) => void;
  alertId: string;
  alertType: AlertTypeResponse;
  refreshAlerts: () => Promise<void>;
}

const AlertCategoryItem: React.FC<Props> = ({
  alertType,
  showModal,
  alertId,
  refreshAlerts,
}) => {
  const { checkPermission } = usePermission()
  const [visibleSocialMedia, setVisibleSocialMedia] = React.useState(false);
  const [loadingDefault, setLoadingDefault] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  const history = useHistory();
  const organizationInfo = useAppSelector((state) => state.organizationSlice);


  async function onCheckedIsDefault() {
    setLoadingDefault(true);
    try {
      await setAlertDefaultById(alertType.id);
      await refreshAlerts();
      setLoadingDefault(false);
    } catch (e) {
      setLoadingDefault(false);
      HandleUIError(e);
    }
  }

  async function onPressDelete(alertTypeId: string) {
    setLoadingDelete(true);
    try {
      await deleteAlertCategory(alertTypeId);
      await refreshAlerts();
      setLoadingDelete(false);
      await createActivityReq('Alert Category Removed Successfully')
    } catch (e) {
      setLoadingDelete(false);
      HandleUIError(e);
    }
  }

  function closeSocialMedia() {
    setVisibleSocialMedia(false);
  }

  function goToAlertActionView() {
    history.push("/alert-type-action", alertType);
  }

  function isDefault() {
    if (typeof alertType.isDefault === "string") {
      return alertType.isDefault === "1";
    }
    return alertType.isDefault;
  }


  const handleButton = () => {
    console.log("alertType?.rel_id", alertType?.rel_id)

    // @ts-ignore
    if (organizationInfo?.is_copy) {
      return (
        <>
          <>

            {checkPermission("Create And Edit Category") && (
              <CIcon
                icon={cilPen}
                className="perimeters-icon m-0"
                size="lg"
                onClick={() => showModal(alertId, alertType.name, alertType.alert_type, 2)}
              />
            )}

            {checkPermission("Delete Category") && (
              <>
                {!loadingDelete ? (
                  <CIcon
                    icon={cilTrash}
                    className="perimeters-icon m-0 ms-1"
                    size="lg"
                    onClick={() => onPressDelete(alertId)}
                  />
                ) : (
                  <Spinner className="is-defualt-spinner" />
                )}
              </>
            )}
          </>

        </>
      )
    } else if (
      //@ts-ignore
      !organizationInfo?.is_copy && !alertType?.rel_id) {
      console.log("alertType?.rel_id", alertType?.rel_id)
      return (
        <>
          <>

            {checkPermission("Create And Edit Category") && (
              <CIcon
                icon={cilPen}
                className="perimeters-icon m-0"
                size="lg"
                onClick={() => showModal(alertId, alertType.name, alertType.alert_type, 2)}
              />
            )}

            {checkPermission("Delete Category") && (
              <>
                {!loadingDelete ? (
                  <CIcon
                    icon={cilTrash}
                    className="perimeters-icon m-0 ms-1"
                    size="lg"
                    onClick={() => onPressDelete(alertId)}
                  />
                ) : (
                  <Spinner className="is-defualt-spinner" />
                )}
              </>
            )}
          </>

        </>
      )
    }
  }


  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-3 px-3">
        <div className="d-flex align-items-center w-50">
          <p className="text-light fs-5 me-5 alert-type-title-settings w-100">
            {alertType.name}
          </p>
        </div>


        <div className="d-flex align-items-center">
          {handleButton()}
        </div>

      </div>
      <Divider horizontalSpace={5} />
      <Modal visible={visibleSocialMedia} >
        <div className="new-staff-container social-media-settings-container">
          <div className="d-flex justify-content-between new-template-header">
            <p className="fs-5">
              Select the social networks for which to notify
            </p>
            <IconButton icon={cilX} onClick={closeSocialMedia} />
          </div>
          <div className="ms-4 mt-2 d-flex justify-content-between">
            <div>
              <div className="d-flex align-items-center">
                <CFormCheck label="Facebook" className="me-2 mb-2" />
                <img src={FacebookIcon} alt="" className="social-media-icon" />
              </div>
              <div className="d-flex align-items-center">
                <CFormCheck label="Twitter" className="me-2 mb-2" />
                <img src={TwitterIcon} alt="" className="social-media-icon" />
              </div>
              <div className="d-flex align-items-center">
                <CFormCheck label="Instagram" className="me-2 mb-2" />
                <img src={InstagramIcon} alt="" className="social-media-icon" />
              </div>
            </div>
            <div className="d-flex align-items-end me-4 mb-2">
              <Button onClick={closeSocialMedia} className="px-5">
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AlertCategoryItem;
