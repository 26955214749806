import * as React from "react";

import { MenuHeader } from "../Atomics";
import { CSpinner } from "@coreui/react";
import "./RoleList.scss";
import { removeContactFromParents } from "../../services/rolesService";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import "./RoleList.scss"
// interfaces
import { Roles } from "../types";
import RolesRow from "./RolesRow";
import { createActivityReq } from "../../services/activityLogService";

interface Props {
  parents: Array<Roles>,
  loading: boolean,
  refreshParents: () => void
}

const RolesList: React.FC<Props> = ({ parents, loading, refreshParents }) => {

  async function removeUser(parentId: number): Promise<void> {
    try {
      await toast.promise(removeContactFromParents(parentId), {
        loading: "Removing role...",
        success: "role removed from organization",
        error: "Error removing role from organization",
      });
      await refreshParents();
      await createActivityReq('Role Removed Successfully')
    } catch (e) {
      HandleUIError(e);
    }
  }

  function onPressRemoveUser(parentId: number) {
    const confirmationDialogOptions = {
      title:
        "Are you sure you want to remove this?",
      confirmAction: () => removeUser(parentId),
    };
    confirmationDialog(confirmationDialogOptions);
  }

  return (
    <>
      <MenuHeader className="d-flex pt-3 px-2 pb-3 justify-content-around table-size">
        <p className="text-color section-width-parents-1 d-none d-sm-block">Role Name</p>
        <p className="text-color section-width-parents-1 d-none d-sm-block">Group Name</p>
        <p className="text-color section-width-parents-1 d-none d-sm-block">Registered User</p>
        <p className="text-color section-width-parents-1 d-none d-sm-block">Unregistered User</p>
        <p className="text-color section-width-parents-4 d-none d-sm-block">Role Type</p>
        <p className="text-color section-width-parents-4 d-none d-sm-block"></p>
      </MenuHeader>
      {!loading ? (
        parents.map((element: Roles, index: number) => {
          return (
            <>
              <RolesRow
                parent={element}
                key={index}
                removeUser={onPressRemoveUser}
                refreshParents={refreshParents}
              />
            </>
          );
        })
      ) : (
        <div className="spinner">
          <CSpinner variant="grow" />
        </div>
      )}
    </>
  );
};

export default RolesList;