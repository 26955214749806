import * as React from "react";
import "../staff/NewStaff.scss";
import "./NewRole.scss"
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";

//components
import {
  Modal,
  Input,
  Button,
  Spinner,
  IconButton,
} from "../Atomics";
import { cilX } from "@coreui/icons";
import { editContactFromParents } from "../../services/rolesService";
import {createActivityReq} from "../../services/activityLogService";

interface DataParents {
    parentId: number;
    name: string;
    email: string;
    phone: string;
    group: string;
    role: string;
}

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshParents: () => void;
  parentData: DataParents
}

const EditRole: React.FC<Props> = ({ visible, closeModal, refreshParents, parentData}) => {
  const [name, setName] = React.useState<string>(parentData.name);
  const [email, setEmail] = React.useState<string>(parentData.email);
  const [phone, setPhone] = React.useState<string>(parentData.phone);
  const [group, setGroup] = React.useState<string>(parentData.group);
  const [roleType, setRoleType] = React.useState<string>(parentData.role);
  const [addParentLoading, setAddParentLoading] = React.useState<boolean>(false);

  const handleInputName = (e: any) => {
    setName(e.target.value);
  };
  
  const handleInputMail = (e: any) => {
    setEmail(e.target.value);
  };
  
  const handleInputPhone = (e: any) => {
    setPhone(e.target.value);
  };

  const handleInputGroup = (e: any) => {
    setGroup(e.target.value);
  };

  const handleRoleType = (e: any) => {
    setRoleType(e.target.value);
  };

  const checkValidate = () => {
    if (name === '' || name === null) {
      return true
    }
    if (group === '' || group === null) {
      return true
    }
    if (roleType === '' || roleType === null) {
      return true
    }
    return false
  }
  
  async function changeParent(parentId: number, name: string, email: string, phone: string, group: string, role: string): Promise<void> {
    setAddParentLoading(true);
      try {
        const check = checkValidate()
        if(check){
          toast.error("Name cannot be empty")
          setAddParentLoading(false);
        }else{
          await editContactFromParents(parentId, name, email, phone, group, role)
          await refreshParents();
          toast.success("Role modified");
          setAddParentLoading(false);
          onCloseModal();
          await createActivityReq('Role Updated Successfully')
        }
      } catch (e) {
        setAddParentLoading(false);
        HandleUIError(e);
      }
  }

  function onCloseModal() {
    closeModal();
  }

  return (
    <Modal visible={visible} >
      <div className="new-staff-container h-auto">
        <div className="new-staff-header">
          <p className="text-light py-2 ps-4 fs-5">Edit Role</p>
          <IconButton icon={cilX} className="me-3" onClick={closeModal} />
        </div>
      
          <div className="mt-5">
            <p className="text-light fs-5 ms-3 pt-3">
              Name
            </p>
            <Input
              value={name}
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputName}
              autoFocus
            />

            <p className="text-light fs-5 ms-3 pt-3">
              Group Name
            </p>
            <Input
                value={group}
                className="mt-2 mb-3 ms-3 search-phone-input"
                onChange={handleInputGroup}
                autoFocus
            />

            <p className="text-light fs-5 ms-3 pt-3 mb-2">
              Role Type
            </p>
            <div className="px-3 mb-4">
              <select
                  className="form-select"
                  style={{
                    backgroundColor: '#38373d',
                    border: '1.5px solid #505050',
                    color: '#51fffe'
                  }}
                  value={roleType}
                  onChange={handleRoleType}
              >
                <option value="">Please Select</option>
                <option value="web">For Web</option>
                <option value="app">For App</option>
              </select>
            </div>

            <Button
              disabled={addParentLoading}
              className="mt-7 button-add px-4 mb-3"
              onClick={() => changeParent(parentData.parentId, name, email, phone, group, roleType)}
            >
              {!addParentLoading ? "Save changes" : <Spinner />}
            </Button>
          </div>
      </div>
    </Modal>
  );
};

export default EditRole;