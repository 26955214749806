import * as React from "react";
import "../../views/Alerts.scss";

//components
import { MenuHeader } from "../Atomics";
import { AlertSettingsItem } from ".";
import CIcon from "@coreui/icons-react";
import { NewAlertType } from ".";

//services
import { getAlerts, getAlertCategory, getAlertTypeCategory } from "../../services/alertServices";
import { AlertTypeResponse } from "../../services/types";
import HandleUIError from "../../utils/HandleUIError";
import { CSpinner } from "@coreui/react";

//icons
import { cilPlus } from "@coreui/icons";
import AlertCategoryModal from "./AlertCategoryModal";
import AlertCategoryItem from "./AlertCategoryItem";
import usePermission from "../../hooks/usePermission";
import { useAppSelector } from "../../redux/hooks";
import Pagination from "../Pagination";

interface props {
  search: any
}
const AlertCategories: React.FC<props> = ({ search }) => {
  const { checkPermission } = usePermission()
  const [visibleNewAlertType, setVisibleNewAlertType] =
    React.useState<boolean>(false);
  const [alerts, setAlerts] = React.useState<AlertTypeResponse[]>();
  const [openedModalFrom, setOpenedModalFrom] = React.useState<number>(1);
  const [selectedAlertType, setSelectedAlertType] = React.useState<string>("");
  const [selectedAlertTypeName, setSelectedAlertTypeName] = React.useState<string>("");
  const [alertCategoryType, setAlertCategoryType] = React.useState<any>([]);
  // const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const PAGE_SIZE = 10;

  const organizationInfo = useAppSelector((state) => state.organizationSlice);

  React.useEffect(function () {
    refreshAlerts();
  }, [search]);

  function showModal(id: typeof selectedAlertType, name: string, type: any, openedFrom: number): void {
    openVisibleNewAlertType();
    setOpenedModalFrom(openedFrom);
    setSelectedAlertType(id);
    setSelectedAlertTypeName(name)
    setAlertCategoryType(type)
  }

  async function refreshAlerts() {
    try {
      setAlerts([]); // Purane data ko clear karne ke liye
      const response = await getAlertTypeCategory(search, currentPage);
      // @ts-ignore
      setAlerts(response.data?.rows || []); // Naya data set karna
      // @ts-ignore
      setNumberOfPages(Math.ceil(response?.data?.count / PAGE_SIZE));
    } catch (e) {
      HandleUIError(e);
    }
  }

  function showEditModal(id: string, name: string, type: any, openedFrom = 2) {
    showModal(id, name, type, openedFrom);
  }

  function openVisibleNewAlertType() {
    setVisibleNewAlertType(true);
    setOpenedModalFrom(1);
  }

  function closeVisibleNewAlertType() {
    setVisibleNewAlertType(false);
  }

  React.useEffect(() => {
    setAlerts([]);
    refreshAlerts();
  }, [currentPage]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <p className="text-color fs-4 mt-3 mb-2">
          Setup the sequence each type of alert will initiate.
        </p>
        {checkPermission("Create And Edit Category") && (
          <div
            className="add-icon-container mg-lt add-icon-staff"
            onClick={openVisibleNewAlertType}
          >
            <CIcon icon={cilPlus} size="xl" className="add-icon" />
          </div>
        )}
      </div>
      <MenuHeader className="d-flex justify-content-between">
        <p className="text-color py-3 px-3">Name</p>
        <p className="text-color py-3 px-3 me-5">Default</p>
      </MenuHeader>
      {alerts ? (
        alerts.map((alert) => {
          return (
            <AlertCategoryItem
              alertType={alert}
              key={alert.name}
              //there cannot be two alerts with the same name
              refreshAlerts={refreshAlerts}
              showModal={showEditModal}
              alertId={alert.id.toString()}
            />
          );
        })
      ) : (
        <div className="d-flex justify-content-center mt-4">
          <CSpinner variant="grow" />
        </div>
      )}


      <div className="mt-3">
        {numberOfPages > 1 && (
          <Pagination
            numberOfPages={numberOfPages}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        )}
      </div>
      {visibleNewAlertType && (
        <AlertCategoryModal
          refreshAlerts={refreshAlerts}
          visible={visibleNewAlertType}
          closeModal={closeVisibleNewAlertType}
          openedFrom={openedModalFrom}
          alertId={selectedAlertType}
          alertName={selectedAlertTypeName}
          alertCategories={alertCategoryType?.map((val: any) => ({ id: val.id, value: val?.name }))}
        />
      )}
    </div>
  );
};

export default AlertCategories;
