// import { current } from "immer";
import * as React from "react";
import "./Pagination.scss";

interface Props {
    numberOfPages: number;
    currentPage: number;
    setCurrentPage: any;
}

interface RenderItemProps {
    page: number;
    selected: boolean;
}

interface PropsRenderEllipsis {
    currentPage: number;
    setCurrentPage: any;
}

const Pagination: React.FC<Props> = ({ numberOfPages, setCurrentPage, currentPage }) => {
    // Ensure `numberOfPages` and `currentPage` are integers
    const totalNumberOfPages = Math.ceil(numberOfPages);
    const currentPg = Math.ceil(currentPage);

    const RenderItem: React.FC<RenderItemProps> = ({ page, selected }) => (
        <div
            className={`pagination-item ${selected ? "pagination-selected" : ""}`}
            onClick={() => setCurrentPage(page)}
        >
            <p className="pagination-text noselect-text">{page}</p>
        </div>
    );

    const RenderEllipsis = () => (
        <div className="pagination-item">
            <p className="pagination-text noselect-text">...</p>
        </div>
    );

    const getVisiblePages = () => {
        const visiblePages: (number | "ellipsis")[] = [];
        if (totalNumberOfPages <= 8) {
            for (let i = 1; i <= totalNumberOfPages; i++) {
                visiblePages.push(i);
            }
        } else {
            // Show 1st page and ellipsis if current page > 4
            if (currentPg > 4) visiblePages.push(1, "ellipsis");
            else for (let i = 1; i <= Math.min(4, totalNumberOfPages); i++) visiblePages.push(i);

            // Show current page range
            const start = Math.max(2, currentPg - 1);
            const end = Math.min(totalNumberOfPages - 1, currentPg + 1);

            for (let i = start; i <= end; i++) {
                if (!visiblePages.includes(i)) visiblePages.push(i);
            }

            // Ensure last page is always visible
            if (!visiblePages.includes(totalNumberOfPages)) {
                if (currentPg < totalNumberOfPages - 3) {
                    visiblePages.push("ellipsis", totalNumberOfPages);
                } else {
                    visiblePages.push(totalNumberOfPages);
                }
            }
        }
        return visiblePages;
    };

    return (
        <div className="d-flex">
            {/* Prev Button */}
            <div
                className={`pagination-prev ${currentPg === 1 ? "disabled" : ""}`}
                onClick={() => currentPg > 1 && setCurrentPage(currentPg - 1)}
            >
                <p className="pagination-text noselect-text">Previous</p>
            </div>

            {/* Visible Pages */}
            {getVisiblePages().map((item, index) => {
                if (item === "ellipsis") return <RenderEllipsis key={index} />;
                return (
                    <RenderItem key={index} page={item as number} selected={item === currentPg} />
                );
            })}

            {/* Next Button */}
            <div
                className={`pagination-next ${currentPg === totalNumberOfPages ? "disabled" : ""}`}
                onClick={() => currentPg < totalNumberOfPages && setCurrentPage(currentPg + 1)}
            >
                <p className="pagination-text noselect-text">Next</p>
            </div>
        </div>
    );
};


export default Pagination;
