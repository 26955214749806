import * as React from "react";
import "../../views/Alerts.scss";

//components
import { Divider, Modal, IconButton, Button, Spinner } from "../Atomics";
import { CFormCheck } from "@coreui/react";
import { useHistory } from "react-router-dom";

//services
import { AlertTypeResponse } from "../../services/types";
import {
  setAlertDefaultById,
  deleteAlertType, setHomeAlert, setAutoAlert, setImmediateAlert,
  setHiddenAlert,
} from "../../services/alertServices";

//icons
import FacebookIcon from "../../assets/fb_icon.svg";
import TwitterIcon from "../../assets/tw_icon.svg";
import InstagramIcon from "../../assets/ig_icon.svg";
import HandleUIError from "../../utils/HandleUIError";
import { cilX, cilSettings, cilPen, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import toast from "react-hot-toast";
import usePermission from "../../hooks/usePermission";
import { createActivityReq } from "../../services/activityLogService";
import { useAppSelector } from "../../redux/hooks";

interface Props {
  showModal: (
    alertId: string,
    name: string,
    icon: any,
    type: any,
    color: string,
    notification_sound_id: string,
    openedFrom: number
  ) => void;
  alertId: string;
  alertType: AlertTypeResponse;
  refreshAlerts: () => Promise<void>;
  alerts: any
}

const AlertSettingsItem: React.FC<Props> = ({
  alertType,
  showModal,
  alertId,
  refreshAlerts,
  alerts,
}) => {
  const { checkPermission } = usePermission()
  const [visibleSocialMedia, setVisibleSocialMedia] = React.useState(false);
  const [loadingDefault, setLoadingDefault] = React.useState(false);
  const [loadingDefault2, setLoadingDefault2] = React.useState(false);
  const [loadingDefault3, setLoadingDefault3] = React.useState(false);
  const [loadingDefault4, setLoadingDefault4] = React.useState(false);
  const [loadingDefault5, setLoadingDefault5] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  const history = useHistory();

  const organizationInfo = useAppSelector((state) => state.organizationSlice);


  async function onCheckedIsDefault() {
    setLoadingDefault(true);
    try {
      await setAlertDefaultById(alertType.id);
      await refreshAlerts();
      setLoadingDefault(false);
      await createActivityReq('Alert Setting Updated Successfully')
    } catch (e) {
      setLoadingDefault(false);
      HandleUIError(e);
    }
  }

  async function onCheckedIsHomeAlertDefault(event: any) {
    let isMoreThanThree;
    // @ts-ignore
    if(organizationInfo?.is_copy){
      isMoreThanThree = alerts?.filter((item: any) => item?.isHomeAlert)?.length - 1
    }else{
      isMoreThanThree = alerts?.filter((item: any) => item?.isHomeAlert && !item?.rel_id)?.length - 1
    }
    
    const checked: any = event?.target?.checked
    console.log('three', isMoreThanThree)
    if (checked) {
      if (isMoreThanThree < 2) {
        setLoadingDefault2(true);
        try {
          await setHomeAlert(alertType.id, checked);
          await refreshAlerts();
          setLoadingDefault2(false);
          await createActivityReq('Alert Setting IsHomeAlert Status Changed Successfully')
        } catch (e) {
          setLoadingDefault2(false);
          HandleUIError(e);
        }
      } else {
        toast.error('Please Checked Less Than 4 Home Alerts');
      }
    } else {
      setLoadingDefault2(true);
      try {
        await setHomeAlert(alertType.id, checked);
        await refreshAlerts();
        setLoadingDefault2(false);
        await createActivityReq('Alert Setting IsHomeAlert Status Changed Successfully')
      } catch (e) {
        setLoadingDefault2(false);
        HandleUIError(e);
      }
    }
  }

  async function onCheckedHiddenSend(event: any) {
    setLoadingDefault5(true);
    const checked: any = event?.target?.checked
    try {
      await setHiddenAlert(alertType.id, checked);
      await refreshAlerts();
      setLoadingDefault5(false);
      await createActivityReq('Alert Setting HiddenSend Status Changed Successfully')
    } catch (e) {
      setLoadingDefault5(false);
      HandleUIError(e);
    }
  }

  async function onCheckedAutoSend(event: any) {
    setLoadingDefault3(true);
    const checked: any = event?.target?.checked
    try {
      await setAutoAlert(alertType.id, checked);
      await refreshAlerts();
      setLoadingDefault3(false);
      await createActivityReq('Alert Setting AutoSend Status Changed Successfully')
    } catch (e) {
      setLoadingDefault3(false);
      HandleUIError(e);
    }
  }

  async function onCheckedImmediateSend(event: any) {
    setLoadingDefault4(true);
    const checked: any = event?.target?.checked
    try {
      await setImmediateAlert(alertType.id, checked);
      await refreshAlerts();
      setLoadingDefault4(false);
      await createActivityReq('Alert Setting Immediate Status Changed Successfully')
    } catch (e) {
      setLoadingDefault4(false);
      HandleUIError(e);
    }
  }

  async function onPressDelete(alertTypeId: string) {
    setLoadingDelete(true);
    try {
      await deleteAlertType(alertTypeId);
      await refreshAlerts();
      await createActivityReq('Alert Setting Removed Successfully')
      setLoadingDelete(false);
    } catch (e) {
      setLoadingDelete(false);
      HandleUIError(e);
    }
  }

  function closeSocialMedia() {
    setVisibleSocialMedia(false);
  }

  function goToAlertActionView() {
    history.push("/alert-type-action", alertType);
  }

  function isDefault() {
    if (typeof alertType.isDefault === "string") {
      return alertType.isDefault === "1";
    }
    return alertType.isDefault;
  }

  function isHomeAlertDefault() {
    if (typeof alertType.isHomeAlert === "string") {
      return alertType.isHomeAlert === "1";
    }
    return alertType.isHomeAlert;
  }

  function isHomeHiddenDefault() {
    if (typeof alertType.hidden === "string") {
      return alertType.hidden === "1";
    }
    return alertType.hidden;
  }

  function isAutoSend() {
    if (typeof alertType.autoSend === "boolean") {
      return alertType.autoSend === true;
    }
    return alertType.autoSend;
  }

  function isImmediateSend() {
    if (typeof alertType.immediateSend === "boolean") {
      return alertType.immediateSend === true;
    }
    return alertType.autoSend;
  }


  // @ts-ignore
  console.log("organizationInfo?.is_copy", organizationInfo?.is_copy)



  const handleButton = () => {
    console.log("alertType?.rel_id", alertType?.rel_id)

    // @ts-ignore
    if (organizationInfo?.is_copy) {
      return (
        <>
          {checkPermission("Create and Edit Setting") && (
            <CIcon
              icon={cilPen}
              className="perimeters-icon m-0"
              size="lg"
              onClick={() =>
                showModal(
                  alertId,
                  alertType.name,
                  alertType.icon,
                  alertType.alert_category,
                  alertType.color,
                  alertType.notification_sound_id,
                  2
                )
              }
            />
          )}


          {checkPermission("Delete Setting") && (
            <>
              {!loadingDelete ? (
                <CIcon
                  icon={cilTrash}
                  className="perimeters-icon m-0 ms-1"
                  size="lg"
                  onClick={() => onPressDelete(alertId)}
                />
              ) : (
                <Spinner className="is-defualt-spinner" />
              )}
            </>
          )}

        </>
      )
    } else if (
      //@ts-ignore
      !organizationInfo?.is_copy && !alertType?.rel_id) {
      console.log("alertType?.rel_id", alertType?.rel_id)
      return (
        <>
          {checkPermission("Create and Edit Setting") && (
            <CIcon
              icon={cilPen}
              className="perimeters-icon m-0"
              size="lg"
              onClick={() =>
                showModal(
                  alertId,
                  alertType.name,
                  alertType.icon,
                  alertType.alert_category,
                  alertType.color,
                  alertType.notification_sound_id,
                  2
                )
              }
            />
          )}


          {checkPermission("Delete Setting") && (
            <>
              {!loadingDelete ? (
                <CIcon
                  icon={cilTrash}
                  className="perimeters-icon m-0 ms-1"
                  size="lg"
                  onClick={() => onPressDelete(alertId)}
                />
              ) : (
                <Spinner className="is-defualt-spinner" />
              )}
            </>
          )}

        </>
      )
    }
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between ">
        <div className="d-flex align-items-center w-50 py-3 px-3">
          <p className="text-light fs-5  alert-type-title-settings w-100 word-break">
            {alertType.name}
          </p>
        </div>
        <div className="d-flex align-items-center w-50 py-3 px-3">
          <p className="text-light fs-5  alert-type-title-settings w-100 word-break">
            {alertType?.alert_category?.name
              ? alertType.alert_category.name
              : "-"}
          </p>
        </div>
        {checkPermission("Create and Edit Setting") && (
          <div className="d-flex align-items-center w-50  py-3 px-3">
            {!loadingDefault2 ? (
              <CFormCheck checked={isHomeAlertDefault()}
                //@ts-ignore
                onChange={(e) => {
                  // @ts-ignore
                  if (!alertType?.rel_id && !organizationInfo?.is_copy) {
                    onCheckedIsHomeAlertDefault(e)
                  }
                  // @ts-ignore
                  if (!alertType?.rel_id && organizationInfo?.is_copy) {
                    onCheckedIsHomeAlertDefault(e)
                  }

                }}
              />
            ) : (
              <Spinner className="is-defualt-spinner" />
            )}
          </div>
        )}
        {checkPermission("Create and Edit Setting") && (
          <div className="d-flex align-items-center w-50 py-3 px-3">

            {!loadingDefault3 ? (
              <>
                {isHomeAlertDefault() && (
                  <CFormCheck checked={isAutoSend()} onChange={onCheckedAutoSend} />
                )}
              </>
            ) : (
              <Spinner className="is-defualt-spinner" />
            )}
          </div>
        )}
        {checkPermission("Create and Edit Setting") && (
          <div className="d-flex align-items-center  py-3 px-3 w-50">
            {!loadingDefault4 ? (
              <>
                {isHomeAlertDefault() && (
                  <CFormCheck
                    checked={isImmediateSend()}
                    onChange={onCheckedImmediateSend} />
                )}
              </>
            ) : (
              <Spinner className="is-defualt-spinner" />
            )}
          </div>
        )}

        {checkPermission("Create and Edit Setting") && (
          <div className="d-flex align-items-center  py-3 px-3 w-50">
            {!loadingDefault5 ? (
              <CFormCheck checked={isHomeHiddenDefault()} onChange={onCheckedHiddenSend} />
            ) : (
              <Spinner className="is-defualt-spinner" />
            )}
          </div>
        )}

        <div className="py-3 px-3 d-flex w-50">

          <div
            className="d-flex align-items-center justify-content-center cursor-pointer noselect-text"
            onClick={goToAlertActionView}
          >
            <CIcon className="text-light m-0 " icon={cilSettings} />
            <p className="text-light mx-1 m-0">Actions</p>
          </div>

          <div className="d-flex align-items-center">

            {handleButton()}
          </div>
        </div>

        {/* {checkPermission("Create and Edit Setting") && (
            <div className="m-0 me-4">
            {!loadingDefault ? (
              <CFormCheck checked={isDefault()} onChange={onCheckedIsDefault} />
              ) : (
                <Spinner className="is-defualt-spinner" />
                )}
                </div>
                )} */}



      </div>
      <Divider horizontalSpace={5} />
      <Modal visible={visibleSocialMedia} >
        <div className="new-staff-container social-media-settings-container">
          <div className="d-flex justify-content-between new-template-header">
            <p className="fs-5">
              Select the social networks for which to notify
            </p>
            <IconButton icon={cilX} onClick={closeSocialMedia} />
          </div>
          <div className="ms-4 mt-2 d-flex justify-content-between">
            <div>
              <div className="d-flex align-items-center">
                <CFormCheck label="Facebook" className="me-2 mb-2" />
                <img src={FacebookIcon} alt="" className="social-media-icon" />
              </div>
              <div className="d-flex align-items-center">
                <CFormCheck label="Twitter" className="me-2 mb-2" />
                <img src={TwitterIcon} alt="" className="social-media-icon" />
              </div>
              <div className="d-flex align-items-center">
                <CFormCheck label="Instagram" className="me-2 mb-2" />
                <img src={InstagramIcon} alt="" className="social-media-icon" />
              </div>
            </div>
            <div className="d-flex align-items-end me-4 mb-2">
              <Button onClick={closeSocialMedia} className="px-5">
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AlertSettingsItem;
