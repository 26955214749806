import * as React from "react";
import "./App.css";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import { DefaultLayout } from "./layout";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import 'bootstrap/dist/css/bootstrap.min.css';

// import {
//   messageListener,
//   handleFcmNotifications,
// } from "./services/pushNotificationService";
// const broadcast = new BroadcastChannel("background-message");

function App() {
  // messageListener();
  //  broadcast.onmessage = (event) => {
  //   handleFcmNotifications(event.data);
  //  };
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DefaultLayout />
      </PersistGate>
    </Provider>
  );
}

export default App;
