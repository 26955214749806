import * as React from "react";

import { MenuHeader } from "../Atomics";
import { CSpinner } from "@coreui/react";
import "./EmergencyList.scss";
import { removeEmergency } from "../../services/emergencyService";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import "./EmergencyList.scss";
// interfaces
import { NewParents, Roles } from "../types";
import EmergencyRow from "./EmergencyRow";
import { createActivityReq } from "../../services/activityLogService";
import EmergencyRowRelation from "./EmergencyRowRelation";
import { removeEmergencyRelation } from "../../services/emergencyRelationService";
import NewParentsRow from "./NewParentsRow";

interface Props {
  parents: any;
  loading: boolean;
  refreshParents: () => void;
}

const NewParentsList: React.FC<Props> = ({
  parents,
  loading,
  refreshParents,
}) => {
  async function removeUser(parentId: number): Promise<void> {
    try {
      await toast.promise(removeEmergencyRelation(parentId), {
        loading: "Removing Emergency Relation Contact...",
        success: "Emergency Contact Relation removed from organization",
        error: "Error removing Emergency Relation Contact from organization",
      });
      await refreshParents();
      await createActivityReq('Emergency Contact Removed Successfully')
    } catch (e) {
      HandleUIError(e);
    }
  }

  function onPressRemoveUser(parentId: number) {
    const confirmationDialogOptions = {
      title: "Are you sure you want to remove this?",
      confirmAction: () => removeUser(parentId),
    };
    confirmationDialog(confirmationDialogOptions);
  }

  return (
    <>

      <MenuHeader className="d-flex pt-3 pb-3 justify-content-around px-2 table-size">
        <p className="text-color section-width-parents-custom d-none d-sm-block">
          Id
        </p>
        <p className="text-color section-width-parents-custom d-none d-sm-block">
          Student Name
        </p>
        <p className="text-color section-width-parents-custom d-none d-sm-block">
          Parent  Name
        </p>
        <p className="text-color section-width-parents-custom d-none d-sm-block">
          Parent Email
        </p>
        <p className="text-color section-width-parents-custom d-none d-sm-block">
          Parent Phone
        </p>
        <p className="text-color section-width-parents-custom d-none d-sm-block">
          Relation
        </p>
        <p className="text-color section-width-parents-custom d-none d-sm-block">
         Status
        </p>
        {/* <p className=" text-color w-auto invisible d-sm-block">
          <div className="margin-icon">
            <span
              className="badge bg-primary text-white"
              role="button"
            >
              View
            </span>
          </div>
        </p> */}
      </MenuHeader>

      {!loading ? (
        parents?.map((element: NewParents, index: number) => {
          return (
            <>
              <NewParentsRow
                parent={element}
                key={index}
                removeUser={onPressRemoveUser}
                refreshParents={refreshParents}
              />
            </>
          );
        })
      ) : (
        <div className="spinner">
          <CSpinner variant="grow" />
        </div>
      )}
    </>
  );
};

export default React.memo(NewParentsList);
