import React, { useEffect, useState } from 'react';
import { IconButton, Input, MenuHeader, PageContainer } from "../components/Atomics";
import { CSpinner } from "@coreui/react";
import { cilPencil } from "@coreui/icons";
import { getAlarmAlerts } from "../services/alarmAlertsService";
import HandleUIError from "../utils/HandleUIError";
import { getActivityReq } from "../services/activityLogService";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { FaList } from "react-icons/fa6";
import CIcon from "@coreui/icons-react";
import { cilPeople } from "@coreui/icons";
import { CAvatar } from "@coreui/react";
import { convertUTCtoLocale } from "../utils/formatConverters";
import { FaArrowLeftLong } from "react-icons/fa6";



const StudentList = () => {
    const params: any = useParams()
    const [loading, setLoading] = useState(false)
    const [logs, setLogs] = useState<any>([]);
    const [refresh, setRefresh] = useState<boolean>(true);

    const getActivityLogs = () => {
        getActivityReq(params?.id)
            .then((res: any) => {
                console.log('users1', res)
                setLogs(res);
            })
            .catch((e: any) => {
                HandleUIError(e);
            });
    }

    const location = useLocation();
    const dataArray = Array.isArray(location.state) ? location.state : [];


    useEffect(() => {
        getActivityLogs()
    }, []);

    const handleRefresh = () => {
        setRefresh(!refresh)
    }


    function deviceIsOnline(deviceHour: string) {
        let hour = moment();
        let hourDevice = moment(convertUTCtoLocale(deviceHour));
        if (hour.diff(hourDevice) <= 300000) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <>
            <div className="bg-main pb-5 over-flow-auto h-100">
                <PageContainer className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4">
                    <div className="row align-items-end justify-content-between mb-4">
                        <div className="col-auto">

                            <div className="d-flex align-items-center">
                                <Link to={"/forward-activity-logs"}>
                                    <FaArrowLeftLong className='me-4 is-hover' size={22} />
                                </Link>

                                <h3 className="text-white mb-0">
                                    Acknowledgement
                                </h3>
                            </div>
                        </div>

                        <div className="col-auto d-none">
                            <button className='btn btn-primary' onClick={handleRefresh}>Refresh</button>
                        </div>
                    </div>


                    <div className="row justify-content-end mb-3 d-none">


                        <div className="col-lg-3 mt-4">
                            <div className="form-group">
                                {/* <label for="exampleFormControlSelect1">Example select</label> */}
                                <select
                                    // onChange={handleChangeOrder}
                                    // value={order}
                                    className="form-select input-select-custom"
                                    id="exampleFormControlSelect1"
                                >
                                    <option value={"DESC"}
                                    // selected={order === "DESC"}
                                    >Descending Order</option>
                                    <option value={"ASC"}
                                    // selected={order === "ASC"}
                                    >Ascending Order</option>
                                    <option value={"Alpha"}
                                    // selected={order === "Alpha"}
                                    >
                                        Alphabatic Order</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-3 mt-4 col-6">
                            <select
                                // onChange={(e: any) => setType(e.target.value)}
                                // value={type}
                                className="form-select input-select-custom"
                                id="exampleFormControlSelect1"
                            >
                                <option value="">All</option>
                                <option value="audio">
                                    Voice
                                </option>
                                <option value="html">
                                    Email
                                </option>
                                <option value="text">
                                    Message
                                </option>
                            </select>
                        </div>

                        <div className="col-lg-3 col-6 mt-4 pe-3">
                            <div className="d-flex">
                                <p className="d-flex text-light align-items-center mg-r-xs">

                                    Search:
                                </p>

                                <Input
                                    type="search"
                                    className="w-100 small pe-2"
                                    placeholder="Search By Name"
                                // value={searchedTitle}
                                // onChange={(e: any) => setSearchedTitle(e.target.value)}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="">
                        <>
                            <MenuHeader className=" justify-content-between table-size">



                                <div className="d-flex border-0 py-3 px-3 user-information-container justify-content-around">

                                    <div className="col d-flex justify-content-start">
                                        <CIcon
                                            icon={cilPeople}
                                            size="xl"
                                            className="staff-icon section-width-1"
                                        />
                                        <p className="ms-3 text-color word-break">
                                            User Name
                                        </p>
                                    </div>
                                    <div className="col d-flex justify-content-center">
                                        <p className="text-color word-break">
                                            Student Id
                                        </p>
                                    </div>


                                    <div className="col d-flex justify-content-center">
                                        <p className="text-color word-break">
                                            Acknowledgement
                                        </p>
                                    </div>

                                </div>

                            </MenuHeader>

                            <div className=" py-3 px-3 user-information-container justify-content-around">
                                {dataArray && dataArray.length > 0 ? (
                                    dataArray.map((item: any) => {
                                        return (
                                            <>
                                                <div className="d-flex mb-3">

                                                    <div className="d-flex col">
                                                        <div className="d-flex justify-content-center">
                                                            {item?.user.avatarFile === null && (
                                                                <>
                                                                    {item?.user.last_login ? (
                                                                        <CAvatar
                                                                            color="primary"
                                                                            textColor="white"
                                                                            size="lg"
                                                                            status={
                                                                                deviceIsOnline(item?.user.last_login)
                                                                                    ? "success"
                                                                                    : "danger"
                                                                            }
                                                                        >
                                                                            {item?.user?.firstName
                                                                                ? item?.user.firstName[0].toUpperCase()
                                                                                : "-"}
                                                                            {item?.user?.lastName
                                                                                ? item?.user.lastName[0].toUpperCase()
                                                                                : "-"}
                                                                        </CAvatar>
                                                                    ) : (
                                                                        <CAvatar
                                                                            color="primary"
                                                                            textColor="white"
                                                                            size="lg"
                                                                            status="danger"
                                                                        >
                                                                            {item?.user?.firstName
                                                                                ? item?.user.firstName[0].toUpperCase()
                                                                                : "-"}
                                                                            {item?.user?.lastName
                                                                                ? item?.user.lastName[0].toUpperCase()
                                                                                : "-"}
                                                                        </CAvatar>
                                                                    )}
                                                                </>
                                                            )}
                                                            {item?.user?.avatarFile !== null && (
                                                                <>
                                                                    {item?.user?.last_login ? (
                                                                        <CAvatar
                                                                            src={item?.user.avatarFile}
                                                                            size="lg"
                                                                            status={
                                                                                deviceIsOnline(item?.user.last_login)
                                                                                    ? "success"
                                                                                    : "danger"
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <CAvatar
                                                                            src={item?.user?.avatarFile}
                                                                            size="lg"
                                                                            status="danger"
                                                                        />
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="ms-3">
                                                            <p className="text-light word-break small">
                                                                {item?.user?.firstName + " " + item?.user?.lastName}
                                                                &nbsp;|&nbsp;{item?.user?.id}
                                                            </p>
                                                            <p className="text-secondary word-break small">
                                                                {item?.user?.email}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col d-flex justify-content-center">
                                                        <p className="text-light word-break">{item?.user?.student_id}</p>
                                                    </div>
                                                    <div className="col d-flex justify-content-center">
                                                        <p className="text-light word-break">Acknowledged</p>
                                                    </div>

                                                </div>
                                            </>
                                        );
                                    })
                                ) : (
                                    <div className="d-flex justify-content-center mt-4">
                                        <h4 className="text-light">No data found</h4>
                                    </div>
                                )}

                            </div>

                            <div className='d-none'>


                                {loading ? (
                                    <div className="d-flex justify-content-center mt-4">
                                        <CSpinner variant="grow" />
                                    </div>
                                ) : (
                                    <>
                                        {logs?.length > 0 ? (
                                            <>
                                                {logs.map((item: any) => (
                                                    <div key={item?.id} className="d-flex py-3 px-3 user-information-container justify-content-around">
                                                        {/* <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break">
                                                            {item?.description ? item?.description : '-'}
                                                        </p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break">
                                                            {item?.createdAt ? moment(item?.createdAt).fromNow() : '-'}
                                                        </p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break">
                                                            {item?.createdAt ? moment(item?.createdAt).fromNow() : '-'}
                                                        </p>
                                                    </div> */}
                                                        <div className="col d-flex justify-content-start">
                                                            <p className="text-light word-break">
                                                                Voice
                                                            </p>
                                                        </div>
                                                        <div className="col d-flex justify-content-start">
                                                            <p className="text-light word-break">
                                                                100
                                                            </p>
                                                        </div>


                                                        <div className="col d-flex justify-content-start">
                                                            <p className="text-light word-break">
                                                                Yes
                                                            </p>
                                                        </div>

                                                        <div className="col d-flex justify-content-start">
                                                            <p className="text-light word-break">
                                                                18-12-2024
                                                            </p>
                                                        </div>

                                                        <div className="col d-flex justify-content-start">
                                                            <p className="text-light word-break">
                                                                Completed
                                                            </p>
                                                        </div>


                                                    </div>
                                                ))}
                                            </>
                                        ) : <h5 className="text-white text-center py-2">DATA NOT FOUND</h5>}
                                    </>
                                )}
                            </div>
                        </>
                    </div>
                </PageContainer>
            </div>
        </>
    );
};

export default StudentList;