import * as React from "react";
import "./Staff.scss";

//components
import { Pagination } from "../components";
import { NewMessage } from "../components/messages";
import { PageContainer } from "../components/Atomics";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import HandleUIError from "../utils/HandleUIError";
// types
import { Roles } from "../components/types";
// services
import { getParents, searchParents } from "../services/rolesService";
import RolesList from "../components/roles/RolesList";
import NewRole from "../components/roles/NewRole"
import useDebounce from "../hooks/useDebounce";
import { useAppSelector } from "../redux/hooks";
import usePermission from "../hooks/usePermission";

const RolesComp: React.FC = () => {
  const { checkPermission } = usePermission()
  const userSlice: any = useAppSelector((state) => state.UserSlice);
  const [visibleNewParent, setVisibleNewParent] = React.useState(false);
  const [currentParents, setCurrentParents] = React.useState<Array<Roles>>([]);
  const [parents, setParents] = React.useState<Array<Roles>>();
  const [numberOfTotalParents, setNumberOfTotalParents] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [parentsPageSize] = React.useState<number>(7);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [pageNumbers, setPageNumbers] = React.useState<number>(1);

  const debouncedSearch = useDebounce(searchValue, 300);

  function searchParentsDebounced() {
    searchParents(parentsPageSize, 1, searchValue).then((parentsFound) => {
      setCurrentPage(1)
      // @ts-ignore
      setCurrentParents(parentsFound.data);
      setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
      setNumberOfTotalParents(parentsFound.numberOfTotalParents);
    })
      .catch((e) => {
        HandleUIError(e);
      });
  }

  React.useEffect(
    function () {
      if (debouncedSearch) {
        searchParentsDebounced();
      } else {
        searchParents(parentsPageSize, 1, searchValue).then((parentsFound) => {
          setCurrentPage(1)
          // @ts-ignore
          setCurrentParents(parentsFound.data);
          setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
          setNumberOfTotalParents(parentsFound.numberOfTotalParents);
        }).catch((e) => {
          HandleUIError(e)
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch]
  );

  React.useEffect(() => {
    refreshParents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  React.useEffect(function () {
    getParents(parentsPageSize, 1).then((res) => {
      setCurrentParents(res);
    }).catch((e) => {
      HandleUIError(e)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeSearchValue(e: any) {
    setSearchValue(e.target.value);
  }

  console.log('currentParents', currentParents)


  //this function is treggered by pagination
  async function refreshParents() {
    setLoading(true);
    try {
      let parentsFound: any = await searchParents(
        parentsPageSize,
        currentPage,
        searchValue
      );
      setCurrentParents(parentsFound.data);
      setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
      setNumberOfTotalParents(parentsFound.numberOfTotalParents);
      setLoading(false);
    } catch (e) {
      HandleUIError(e);
      setLoading(false);
    }
  }

  function onPressAdd() {
    setVisibleNewParent(!visibleNewParent);
  }

  return (
    <div className="bg-main pb-5 over-flow-auto h-100">
      <PageContainer className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4">
        <div className="d-flex flex-custom-row flex-column justify-content-between staff-menu">

          <div>
            <h3 className="text-white">
              Roles
            </h3>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-dir-column">



            <div className="wrap mg-bot mg-lt center-text d-none">
              <p className="text-light me-2">Search:</p>
              <input
                value={searchValue}
                onChange={onChangeSearchValue}
                className="input-search"
                type="text"
              />
            </div>



            <div className="d-flex align-self-end mt-3 mt-xxl-0 buttons-right wrap">
              {checkPermission("Create and Edit Role") && (
                <div
                  className="add-icon-container mg-lt add-icon-staff"
                  onClick={onPressAdd}
                >
                  <CIcon icon={cilPlus} size="xl" className="add-icon" />
                </div>
              )}

            </div>
          </div>
        </div>
        <div className="overflowX">
          <RolesList
            parents={currentParents}
            loading={loading}
            refreshParents={refreshParents}
          />
        </div>
        {pageNumbers > 1 ? (
          <Pagination
            numberOfPages={pageNumbers}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        ) : (
          <></>
        )}
      </PageContainer>
      <NewRole
        visible={visibleNewParent}
        closeModal={onPressAdd}
        refreshParents={refreshParents}
      />
      <NewMessage />
    </div>
  );
};

export default RolesComp;