import * as React from "react";
import "../staff/NewStaff.scss";
import "./NewParent.scss"
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";

//components
import {
  Modal,
  Input,
  Button,
  Spinner,
  IconButton,
} from "../Atomics";
import { cilX } from "@coreui/icons";
import { saveNewParents, saveParents } from "../../services/parentsService";
import { createActivityReq } from "../../services/activityLogService";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { getEmergencyRelations, getStudentId } from "../../services/newParentService";
import { EmergencyRelation, User } from "../types";

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshParents: () => void;
  setRefreshPage: (e: any) => void;
  refreshPage: boolean;
}

const NewParent: React.FC<Props> = ({ visible, closeModal, refreshParents, setRefreshPage, refreshPage }) => {
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [searchedId, setSearchedId] = React.useState<string>("");
  const [relationId, setRelationId] = React.useState<string>();
  const [addParentLoading, setAddParentLoading] = React.useState<boolean>(false);
  const [relationLoading, setRelationLoading] = React.useState<boolean>(false);
  const [currentRelation, setCurrentRelation] = React.useState<any>([]);
  const [studentId, setStudentId] = React.useState<any>([]);
  const [studentDropdown, setStudentDropdown] = React.useState<boolean>(false);
  const [studentIdLoader, setStudentIdLoader] = React.useState<boolean>(false);

  const [searchStudentName, setSearchStudentName] = React.useState<string>("");
  const [searchStudentId, setSearchStudentId] = React.useState<string>();


  const handleInputFirstName = (e: any) => {
    setFirstName(e.target.value);
  };
  const handleInputLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleInputMail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleInputPhone = (e: any) => {
    setPhone(e.target.value);
  };

  const handleInputRelationId = (e: any) => {
    setRelationId(e.target.value);
  };

  const handleSearchId = (e: any) => {
    setSearchStudentName(e.target.value);
    if (e.target.value.length >= 3) {
      setStudentDropdown(true)
      reFetchStudentId(e.target.value)
    }
  };

  async function addParent(name: string, email: string, phone: string): Promise<void> {
    setAddParentLoading(true);
    try {
      if (name === "" || name === null) {
        toast.error("Name cannot be empty")
        setAddParentLoading(false);
      } else {
        await saveParents(name, email, phone);
        await refreshParents();
        toast.success("Contact added to community");
        setAddParentLoading(false);
        onCloseModal();
        await createActivityReq('Contact Successfully Joined the Community.')
      }
    } catch (e) {
      setAddParentLoading(false);
      HandleUIError(e);
    }
  }

  async function addNewParent(
    firstName: string,
    lastName: string,
    relationId: string,
    searchStudentId: string,
    email: string,
    phone: string,
  ): Promise<void> {
    setAddParentLoading(true);
    try {
      if (firstName === "" || firstName === null) {
        toast.error("Name cannot be empty")
        setAddParentLoading(false);
      } else {
        await saveNewParents(firstName, lastName, relationId, searchStudentId, email, phone);
        setRefreshPage(!refreshPage);
        toast.success("Contact added to community");
        setAddParentLoading(false);
        onCloseModal();
        await createActivityReq('Contact Successfully Joined the Community.')
      }
    } catch (e) {
      setAddParentLoading(false);
      HandleUIError(e);
    }
  }


  const reFetch = () => {
    setRelationLoading(true);
    getEmergencyRelations()
      .then((res: any) => {
        setCurrentRelation(res)
        console.log("first res", res)
      })
      .catch((e) => {
        HandleUIError(e);
      })
      .finally(() => {
        setRelationLoading(false)
      })
  }

  const reFetchStudentId = (student: string) => {
    setStudentIdLoader(true)
    getStudentId(student)
      .then((res: any) => {
        setStudentId(res)
        console.log("first res", res)
      })
      .catch((e) => {
        HandleUIError(e);
      })
      .finally(() => {
        setStudentIdLoader(false)
      })
  }

  const handleSearchedStudent = (item: any) => {
    setSearchStudentId(item?.id)
    setSearchStudentName(item?.student_id + ' - ' + item?.firstName + ' ' + item?.lastName)
    setStudentDropdown(false)
    setStudentId([])
  }

  React.useEffect(() => {
    reFetch();
  }, []);

  // React.useEffect(() => {
  //   reFetchStudentId("00-006");
  // }, []);

  function onCloseModal() {
    setFirstName("");
    setLastName("");
    setRelationId("");
    setSearchStudentId("");
    setEmail("");
    setPhone("");
    closeModal();
  }



  return (
    <Modal visible={visible} >
      <div className="new-staff-container">
        <div className="new-staff-header">
          <p className="text-light py-2 ps-4 fs-5">Add new contact to community</p>
          <IconButton icon={cilX} className="me-3" onClick={closeModal} />
        </div>

        <div className="parents-container mt-5 pt-3" >
          <p className="text-light fs-5 ms-3 pt-3">
            Search Student Id
          </p>
          <Input
            className="mt-2  ms-3 search-phone-input"
            onChange={handleSearchId}
            autoFocus
            value={searchStudentName}
          />

          {studentDropdown && (
            <div className="ms-3 me-4 border-secondary border id-dropdown" >
              <ul className="ms-0  pe-0" style={{ listStyleType: "none" }}>
                {Array.isArray(studentId) && studentId?.map((item: User, i) => {
                  return (
                    <>
                      <li className="li-hover" onClick={() => handleSearchedStudent(item)} key={i}>{item?.student_id} - {item?.firstName} {item?.lastName}</li>
                    </>
                  )
                })}
              </ul>
            </div>
          )}


          <p className="text-light fs-5 ms-3 pt-3">
            First Name
          </p>
          <Input
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputFirstName}
            autoFocus
          />
          <p className="text-light fs-5 ms-3 pt-3">
            Last Name
          </p>
          <Input
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputLastName}
            autoFocus
          />
          <p className="text-light fs-5 ms-3 pt-3">
            Relation
          </p>
          <select onChange={handleInputRelationId} className="text-white input-custom mt-2 mb-3 ms-3  search-phone-input bg-transparent" aria-required="true">
            {currentRelation?.length > 0 ? (
              currentRelation.map((item: EmergencyRelation) => {
                return (
                  <option value={item?.id}>{item?.name}</option>
                )
              })

            ) : (
              <option value="">No Data Available</option>
            )}
          </select>
          <p className="text-light fs-5 ms-3 pt-3">
            Email
          </p>
          <Input
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputMail}
          />

          <p className="text-light fs-5 ms-3 pt-3">
            Phone
          </p>
          <PhoneInput
            defaultCountry="US"
            value={phone}
            className="ms-3 me-4 mt-2 bg-transparent "
            onChange={(e: any) => { setPhone(e) }}
          />

          {/* <p className="text-light fs-5 ms-3 pt-3">
            Phone
          </p>
          <Input
            type="number"
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputPhone}
          /> */}
          <Button
            disabled={addParentLoading}
            className="mt-7 button-add px-4 mb-3"
            onClick={() => addNewParent(firstName, lastName, relationId || '', searchStudentId || '', email, phone)}
          >
            {!addParentLoading ? "Add" : <Spinner />}
          </Button>
        </div>
      </div >
    </Modal >
  );
};

export default NewParent;
