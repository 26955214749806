import * as React from "react";
import "./Atomics.scss";

interface GroupButtonsProps {
  className?: string;
}

const GroupButtons: React.FC<GroupButtonsProps> = ({ children, className }) => {
  return (
    <div className={`btn-group ${className}`} role="group">
      {children}
    </div>
  );
};

interface ButtonForGroupProps {
  label?: string;
  checked?: boolean;
  onClick?: () => void;
  className?: string;
}

const ButtonForGroup: React.FC<ButtonForGroupProps> = ({
  label,
  checked,
  onClick,
  className,
}) => {
  const checkedStyle = "btn btn-success";
  const checkedStyleFont = "text-light";
  const noCheckedStyle = "btn btn-outline-dark";
  return (
    <>
      <button
        className={`button-group ${checked ? `${checkedStyle} ${checkedStyleFont} ` : noCheckedStyle
          } ${className}`}
        onClick={onClick}
      >
        {label}
      </button >
    </>
  );
};

export { GroupButtons, ButtonForGroup };
