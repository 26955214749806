import * as React from "react";
import "./Dashboard.scss";

//components
import {
  BatteryStatus,
  ImpromptuMessage,
  QuickAlerts,
  SchoolDetails,
  UserLocationStatus,
} from "../components/dashboard";
import usePermission from "../hooks/usePermission";
import { useAppSelector } from "../redux/hooks";


const Dashboard: React.FC = () => {
  const { checkPermission } = usePermission()

  const organizationInfo = useAppSelector((state) => state.organizationSlice);

  return (
    <div className="p-4 bg-main d-md-flex h-100" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
      {/* <div className=" justify-content-between flex-md-row w-100">
        <StaffNumbers />
      </div>
      <div className="d-flex justify-content-between mt-3 flex-md-row flex-column low-container">
        <div className="lowBatteryContainer rounded mb-2 mb-md-0">
          <LowBatteryDevices />
        </div>
        <div className="rounded lowBatteryContainer">
          <AlertInProgress />
        </div>
      </div> */}
      <div className="w-100 row g-2 row-cols-md-2">
        {checkPermission('View Quick Alert') && (
          <QuickAlerts />
        )}
        {
          //@ts-ignore
          !organizationInfo?.is_copy && (
            <>
              <ImpromptuMessage />
            </>
          )
        }
      </div>
      <div className="w-100 ms-sm-2">
        <UserLocationStatus />
        {
          //@ts-ignore
          !organizationInfo?.is_copy && (
            <>
              <BatteryStatus />
              <SchoolDetails />
            </>
          )
        }
      </div>
    </div>
  );
};

export default Dashboard;
