import * as React from "react";

//components
import { PageContainer, Button, MenuHeader, Divider, Input } from "../Atomics";
import { PreStoredMessagesItem, NewTemplate } from ".";
import { CSpinner } from "@coreui/react";
import { GroupButtons, ButtonForGroup } from "../Atomics/GroupButtons";
import { Pagination } from "..";
import checklist from "../../assets/checklist.png";
import add from "../../assets/add.png";

//services
import {
  getAllCustomTemplatesForPagination,
  getAllStandardTemplatesForPagination,
} from "../../services/messagesService";
import { TemplateMessageResponse } from "../../services/types";
import HandleUIError from "../../utils/HandleUIError";
import usePermission from "../../hooks/usePermission";

interface SwitchTemplatesProps {
  custom: boolean;
  standard: boolean;
}

const PreStoredMessages: React.FC = () => {
  const { checkPermission } = usePermission()
  const [visibleNewTemplate, setVisibleNewTemplate] = React.useState(false);
  const [templates, setTemplates] = React.useState<TemplateMessageResponse[]>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [numberOfPages, setNumberOfPages] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [type, setType] = React.useState("");
  const [templatesPageSize] = React.useState<number>(7);
  const [switchTemplate, setSwitchTemplate] =
    React.useState<SwitchTemplatesProps>({
      custom: true,
      standard: false,
    });

  const [searchedTitle, setSearchedTitle] = React.useState("");
  const [order, setOrder] = React.useState("DESC");
  const [loading, setLoading] = React.useState(false)


  function changeToCustom() {
    setSwitchTemplate({
      custom: true,
      standard: false,
    });
  }

  function changeToStandard() {
    setSwitchTemplate({
      custom: false,
      standard: true,
    });
  }

  React.useEffect(
    function () {
      getAllTemplates();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [switchTemplate.custom, currentPage, type, searchedTitle]
  );

  React.useEffect(
    function () {
      setCurrentPage(1);
    },
    [switchTemplate.custom]
  );

  async function getAllTemplates() {
    setIsLoading(true);
    try {
      let templatesResponse: {
        data: TemplateMessageResponse[];
        numberOfTotalPages: number;
      };
      if (switchTemplate.custom) {
        templatesResponse = await getAllCustomTemplatesForPagination(
          templatesPageSize,
          currentPage,
          type,
          searchedTitle,
          order
        );
      } else {
        templatesResponse = await getAllStandardTemplatesForPagination(
          templatesPageSize,
          currentPage,
          type,
          searchedTitle,
          order
        );
      }
      setTemplates(templatesResponse.data);
      console.log("templatesPageSize", templatesPageSize)
      console.log("numberOfTotalPages", templatesResponse.numberOfTotalPages)
      setNumberOfPages(
        templatesResponse.numberOfTotalPages / templatesPageSize
      );
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      HandleUIError(e);
    }
  }



  function closeVisibleNewTemplate() {
    setVisibleNewTemplate(false);
  }
  function openVisibleNewTemplate() {
    setVisibleNewTemplate(true);
  }

  const handleChangeOrder = (e: any) => {
    setOrder(e.target.value)
  }


  React.useEffect(() => {
    orderSorting(order)
  }, [order, type])

  const orderSorting = async (order: any) => {
    if (switchTemplate.custom) {

      try {
        const res = await getAllCustomTemplatesForPagination(
          templatesPageSize,
          currentPage,
          searchedTitle,
          type,
          order
        );
        // setLoading(false)
        console.log("data", res?.data);
        setTemplates(res.data);
      } catch (e) {
        setLoading(false);
        HandleUIError(e);
      }
    } else
      try {
        const res = await getAllStandardTemplatesForPagination(
          templatesPageSize,
          currentPage,
          searchedTitle,
          type,
          order
        );
        // setLoading(false)
        console.log("data", res?.data);
        setTemplates(res.data);
      } catch (e) {
        setLoading(false);
        HandleUIError(e);
      }
  }



  const handleSearched = (e: any) => {
    setSearchedTitle(e.target.value)
    setCurrentPage(1)

  }



  return (
    <>
      <div className="col-lg-12 ">
        <PageContainer className="d-flex flex-column justify-content-between m-0 pb-3 p-0">
          <div>
            <div className=" d-flex px-4 justify-content-between align-items-center messages-header-container">
              <p className="text-light fs-5">Pre-Stored Messages</p>
              {checkPermission("Create and Edit Message") && (
                <>
                  {switchTemplate.custom && (
                    <Button
                      className="buttons-messages-screen"
                      onClick={openVisibleNewTemplate}
                    >
                      New
                    </Button>
                  )}
                </>
              )}

            </div>
            <div className="row justify-content-between">
              <div className="col-lg-3">
                <GroupButtons className="mt-4 ms-4">
                  <ButtonForGroup
                    //@ts-ignore
                    label={
                      <>
                        <img src={add} width={25} height={25} alt="" />
                        Custom

                      </>
                    }
                    onClick={changeToCustom}
                    checked={switchTemplate.custom}
                  />
                  <ButtonForGroup
                    //@ts-ignore
                    label={
                      <>
                        <img src={checklist} width={25} height={25} alt="" />
                        standard

                      </>
                    }
                    checked={switchTemplate.standard}
                    onClick={changeToStandard}
                  />
                </GroupButtons>
              </div>

              <div className="col-lg-3 mt-4">
                <div className="form-group">
                  {/* <label for="exampleFormControlSelect1">Example select</label> */}
                  <select
                    onChange={handleChangeOrder}
                    value={order}
                    className="form-select input-select-custom"
                    id="exampleFormControlSelect1"
                  >
                    <option value={"DESC"} selected={order === "DESC"}>Descending Order</option>
                    <option value={"ASC"} selected={order === "ASC"}>Ascending Order</option>
                    <option value={"Alpha"} selected={order === "Alpha"}>Alphabatic Order</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-3 mt-4 col-6">
                <select
                  onChange={(e: any) => setType(e.target.value)}
                  value={type}
                  className="form-select input-select-custom"
                  id="exampleFormControlSelect1"
                >
                  <option value="">All</option>
                  <option value="audio">
                    Voice
                  </option>
                  <option value="html">
                    Email
                  </option>
                  <option value="text">
                    Message
                  </option>
                </select>
              </div>

              <div className="col-lg-3 col-6 mt-4 pe-4">
                <div className="d-flex">
                  <p className="d-flex text-light align-items-center mg-r-xs">

                    Search:
                  </p>

                  <Input
                    type="search"
                    className="w-100 small pe-2"
                    placeholder="Search By Name"
                    value={searchedTitle}
                    onChange={handleSearched}
                  />
                </div>

              </div>
            </div>
            <MenuHeader className="d-flex py-3 mx-4 mt-4">
              <p className="text-color ps-2 w-50">Messages</p>
              <p className="text-color w-50 me-5">Type</p>
            </MenuHeader>
            {!isLoading ? (
              templates?.filter(template => {
                if (switchTemplate.standard) {
                  return template.status === true;
                }
                return template.status === false;
              })
                .map((template, index) => {
                  return (
                    <>
                      <PreStoredMessagesItem
                        key={index}
                        name={template.name}
                        id={template.id}
                        refreshTemplates={getAllTemplates}
                        isText={template.type === "text"}
                        isEmail={template.type === "html"}
                        isAudio={template.type === "audio"}
                        text={template.text}
                        title={template.title}
                        category={template.category}
                        filePath={template.filePath}
                        status={template.status}
                      />
                      {index !== templates.length - 1 && (
                        <div className="px-4">
                          <Divider />
                        </div>
                      )}
                    </>
                  );
                })
            ) : (
              <div className="d-flex mt-5 justify-content-center">
                <CSpinner variant="grow" />
              </div>
            )}
          </div>
          {numberOfPages > 1 && (
            <div className="ms-4 my-3">
              <Pagination
                numberOfPages={numberOfPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </PageContainer>
      </div>
      <NewTemplate
        refreshTemplates={getAllTemplates}
        visible={visibleNewTemplate}
        closeModal={closeVisibleNewTemplate}
      />
    </>
  );
};

export default PreStoredMessages;
