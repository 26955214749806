import * as React from "react";
import toast from "react-hot-toast";

//components
import { CButton, CSpinner } from "@coreui/react";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";

//services
import { getAlerts, throwAlertById } from "../../services/alertServices";
import { AlertTypeResponse } from "../../services/types";
import HandleUIError from "../../utils/HandleUIError";
import { useAppSelector } from "../../redux/hooks";
import { useHistory } from "react-router-dom";
import { getButtonStyle } from "../../helpers/uiTransforms";
import SendQuickAlerts from "./SendQuickAlerts";
import { getAllGroups } from "../../services/groupService";
import { useEffect, useState } from "react";
import usePermission from "../../hooks/usePermission";
import { createActivityReq } from "../../services/activityLogService";

const Alert: React.FC = () => {
  const { checkPermission } = usePermission()
  const [alertTypes, setAlertTypes] = React.useState<AlertTypeResponse[]>();

  const userId = useAppSelector((state) => state.UserSlice.user.id);
  const history = useHistory();
  const [isEdit, setIsEdit] = React.useState<any>(null);
  const [groups, setGroups] = React.useState<any>([]);


  async function getTemplates() {
    try {
      setGroups([]);
      const groupsResponse: any = await getAllGroups();
      setGroups(groupsResponse);
    } catch (error) {
      HandleUIError(error);
    }
  }

  useEffect(() => {
    getTemplates().catch((e) => console.warn(e));
  }, []);

  React.useEffect(function () {
    getAlerts()
      .then((response) => {
        setAlertTypes(response.data);
      })
      .catch((e) => {
        HandleUIError(e);
      });
  }, []);

  function onPressSendAlert(alertType: string, alertId: number) {
    const confirmationDialogOptions = {
      title: `Are you sure you want to send this alert? (${alertType})`,
      confirmAction: () => {
        onPressThrowAlert(alertId).catch((e) => console.warn(e));
      },
    };
    confirmationDialog(confirmationDialogOptions);
  }

  async function onPressThrowAlert(alertTypeId: number) {
    try {
      await throwAlertById(alertTypeId, userId);
      history.push("/");
      // window.location.reload();
      toast.success("Alert thrown successfully");
      await createActivityReq('Quick Alert Send Successfully')
    } catch (e) {
      HandleUIError(e);
    }
  }

  return (
    <>
      <div className="new-staff-container w-100">
        <div className="card-header d-flex justify-content-between align-items-center">
          <p className="text-light py-2 ps-4 fs-5">Quick Alerts</p>
          {/* <IconButton icon={cilX} onClick={closeModal} /> */}
        </div>
        <div className="alert-container alert-card" style={{ height: "455px" }}>
          <div className="buttons-alerts-container pt-2">
            {alertTypes ? (
              alertTypes.map((alertType) => {
                return (
                  <CButton
                    key={alertType.name}
                    shape="rounded-pill"
                    size="lg"
                    className="button-alert bg-button-alert-red m-0 p-0 py-1 mt-2 w-100"
                    style={getButtonStyle(alertType.color)}
                    onClick={() => {
                      checkPermission('Send Quick Alert') && onPressSendAlert(alertType.name, alertType.id)
                    }}
                  // onClick={() => setIsEdit(alertType)}
                  >
                    <p className="alert-text fs-5 px-3">{alertType.name} </p>
                  </CButton>
                );
              })
            ) : (
              <CSpinner variant="grow" className="text-dark mt-5" />
            )}
          </div>
        </div>
      </div>

      {isEdit && (
        <SendQuickAlerts
          visible={Boolean(isEdit)}
          closeModal={() => setIsEdit(null)}
          isEdit={isEdit}
          groups={groups}
        />
      )}
    </>
  );
};

export default Alert;
