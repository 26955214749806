import React, { useState } from 'react'
import { PageContainer } from '../components/Atomics'
import UsePermission from '../hooks/usePermission'
import { ButtonForGroup, GroupButtons } from '../components/Atomics/GroupButtons'
import EmergencyAlert from './EmergencyAlert'
import Alerts from './Alerts'
import GroupAlert from './GroupAlert'


const Emergency = () => {

    const { checkPermission } = UsePermission()
    const [switchTabs, setSwitchTabs] = useState("emergencyAlerts");
    

    return (

        <div className="bg-main pb-3 pt-2 over-flow-auto h-100 pb-3">
            <div className=" rounded  m-2 mt-5 mt-sm-4 mx-sm-4 ">
                <PageContainer className="d-flex flex-column justify-content-between m-0  p-0">
                    <div>
                        <div className=" d-flex px-4 justify-content-between align-items-center messages-header-container">
                            <p className="text-light fs-5">Inbound Alerts Logs
                            </p>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-lg-auto">
                                <GroupButtons className="mt-4 ms-4">

                                    <ButtonForGroup
                                        //@ts-ignore
                                        label="Emergency Alerts"
                                        onClick={() => setSwitchTabs("emergencyAlerts")}
                                        checked={switchTabs === "emergencyAlerts"}
                                    />
                                    <ButtonForGroup
                                        //@ts-ignore
                                        label="Other Alerts"
                                        className=''
                                        onClick={() => setSwitchTabs("otherAlerts")}
                                        checked={switchTabs === "otherAlerts"}
                                    />
                                    <ButtonForGroup
                                        //@ts-ignore
                                        label="Group Message"
                                        onClick={() => setSwitchTabs("groupMessages")}
                                        checked={switchTabs === "groupMessages"}
                                    />
                                </GroupButtons>
                            </div>

                            <div className="col-12">

                                {
                                    switchTabs === "otherAlerts" && (
                                        <Alerts />
                                    )
                                }

                                {
                                    switchTabs === "emergencyAlerts" && (
                                        <EmergencyAlert />
                                    )
                                }

                                {
                                    switchTabs === "groupMessages" && (
                                        <GroupAlert />
                                    )
                                }
                            </div>
                        </div>


                    </div>




                </PageContainer>

            </div>

        </div>

    )
}

export default Emergency