import * as React from "react";
import "./Staff.scss";

//components
import { Pagination } from "../components";
import { NewMessage } from "../components/messages";
import { Input, PageContainer } from "../components/Atomics";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import HandleUIError from "../utils/HandleUIError";
// types
import { Roles } from "../components/types";
// services
import { getParents, searchParents } from "../services/groupAlertService";
import GroupList from "../components/groupAlert/GroupList";
import NewRole from "../components/groupAlert/NewRole";
import useDebounce from "../hooks/useDebounce";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const GroupAlert: React.FC = () => {
  const [visibleNewParent, setVisibleNewParent] = React.useState(false);
  const [currentParents, setCurrentParents] = React.useState<Array<Roles>>([]);
  const [parents, setParents] = React.useState<Array<Roles>>();
  const [numberOfTotalParents, setNumberOfTotalParents] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [parentsPageSize] = React.useState<number>(7);
  const [searchValue, setSearchValue] = React.useState<string>("");

  const MAX_SIZE_ORGANIZATIONS = 10;
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [alertType, setAlertType] = useState<any>('')
  const [search, setSearch] = useState<any>('')

  const debouncedSearch = useDebounce(searchValue, 300);

  // function searchParentsDebounced() {
  //   searchParents(parentsPageSize, 1, searchValue)
  //     .then((parentsFound) => {
  //       setCurrentPage(1);
  //       // @ts-ignore
  //       setCurrentParents(parentsFound.data);
  //       setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
  //       setNumberOfTotalParents(parentsFound.numberOfTotalParents);
  //     })
  //     .catch((e) => {
  //       HandleUIError(e);
  //     });
  // }

  // React.useEffect(
  //   function () {
  //     if (debouncedSearch) {
  //       searchParentsDebounced();
  //     } else {
  //       searchParents(parentsPageSize, 1, searchValue)
  //         .then((parentsFound) => {
  //           setCurrentPage(1);
  //           // @ts-ignore
  //           setCurrentParents(parentsFound.data);
  //           setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
  //           setNumberOfTotalParents(parentsFound.numberOfTotalParents);
  //         })
  //         .catch((e) => {
  //           HandleUIError(e);
  //         });
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [debouncedSearch]
  // );

  const reFetch = (limit: any, page: any, search?: any, type?: any) => {
    setLoading(true)
    getParents(limit, page, search, type)
      .then((res: any) => {
        setCurrentParents(res?.rows);
        setNumberOfPages(res?.count / MAX_SIZE_ORGANIZATIONS);
      })
      .catch((e) => {
        HandleUIError(e);
      })
      .finally(() => {
        setLoading(false)
      })
  }

  React.useEffect(() => {
    reFetch(MAX_SIZE_ORGANIZATIONS, currentPage, '', alertType)
    if (search.trim()?.length > 2) {
      reFetch(MAX_SIZE_ORGANIZATIONS, currentPage, search, alertType)
    } else {
      // Clear results if query is empty
      setCurrentParents([]);
    }
  }, [search])

  React.useEffect(() => {
    reFetch(MAX_SIZE_ORGANIZATIONS, currentPage, search, alertType)
  }, [alertType]);

  React.useEffect(() => {
    reFetch(MAX_SIZE_ORGANIZATIONS, currentPage, search, alertType)
  }, [currentPage]);

  React.useEffect(function () {
    reFetch(MAX_SIZE_ORGANIZATIONS, currentPage, search, alertType)
  }, []);

  function onChangeSearchValue(e: any) {
    setSearchValue(e.target.value);
  }

  console.log("currentParents", currentParents);

  //this function is treggered by pagination
  // async function refreshParents() {
  //   setLoading(true);
  //   try {
  //     let parentsFound: any = await searchParents(
  //       parentsPageSize,
  //       currentPage,
  //       searchValue
  //     );
  //     setCurrentParents(parentsFound.data);
  //     setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
  //     setNumberOfTotalParents(parentsFound.numberOfTotalParents);
  //     setLoading(false);
  //   } catch (e) {
  //     HandleUIError(e);
  //     setLoading(false);
  //   }
  // }

  function onPressAdd() {
    setVisibleNewParent(!visibleNewParent);
  }


  const handleSearch = (e: any) => {
    setSearch(e.target.value)
    setCurrentPage(1)
  }

  const history = useHistory()


  return (
    <div className="bg-main pb-5 over-flow-auto h-100">
      <PageContainer
        // className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4"
        className={`${history?.location?.pathname === "/emergency" ? "mx-0 mt-0" : "page-container-staff d-flex flex-column justify-content-start pb-3 mx-4"}`}

      >
        <div className="d-flex flex-custom-row flex-column staff-menu">
          <div className="d-flex align-items-center w-100">
            <div className="col-lg-auto col-sm-3">
              <h3 className="text-light me-auto mb-4">Group Message</h3>
            </div>
            <div className="col-lg col-sm-9">
              <div className="row justify-content-end align-items-end">
                <div className="col-lg-4 col-6">
                  <select
                    onChange={(e: any) => setAlertType(e.target.value)}
                    value={alertType}
                    className="form-select input-select-custom"
                    id="exampleFormControlSelect1"
                  >
                    <option value="">Sort By</option>
                    <option value="Voice">
                      Voice
                    </option>
                    <option value="Image">
                      Image
                    </option>
                    <option value="Message">
                      Message
                    </option>
                  </select>
                </div>
                <div className="col-lg-4 col-6">
                  <Input
                    type="search"
                    className="w-100 small pe-2"
                    placeholder="Search"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
                <div className="col-auto">

                  <button
                    className="btn btn-primary position-relative shadow-none"
                    onClick={() => reFetch("", "", "", "")}
                  >
                    Refresh
                  </button>
                </div>


              </div>
            </div>
            <div className="d-flex align-self-end mt-3 mt-xxl-0 buttons-right wrap d-none">

              <div
                className="add-icon-container mg-lt add-icon-staff"
                onClick={onPressAdd}
              >
                <CIcon icon={cilPlus} size="xl" className="add-icon" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="overflowX">

            <GroupList
              parents={currentParents}
              loading={loading}
              refreshParents={() => reFetch(MAX_SIZE_ORGANIZATIONS, currentPage)}
              alert={''}
              setEditData={() => { }}
            />
          </div>
        </div>
        <div className="py-3">
          {numberOfPages > 1 ? (
            <Pagination
              numberOfPages={numberOfPages}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          ) : (
            <></>
          )}
        </div>
      </PageContainer>
      <NewRole
        visible={visibleNewParent}
        closeModal={onPressAdd}
        refreshParents={() => reFetch(MAX_SIZE_ORGANIZATIONS, currentPage)}
      />
      <NewMessage />
    </div>
  );
};

export default GroupAlert;
