import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Login from "../views/auth/Login";
import ForgetPassword from "../views/auth/forgetPassword";
import Verification from "../views/auth/verification";
import OtpScreen from "../views/auth/otpScreen";
import PrivacyPolicy from '../components/PrivacyPolicy';
import Abc from '../views/abc';

const AppRouting = () => {
    return (
        <BrowserRouter>

            <Switch>
                <Route exact path="/">
                    <Login />
                </Route>
                <Route exact path="/privacy-policy">
                    <PrivacyPolicy />
                </Route>
                <Route path="/new-password">
                    <ForgetPassword />
                </Route>
                <Route path="/verify">
                    <Verification />
                </Route>
                <Route path="/reset-password">
                    <OtpScreen />
                </Route>
                {/* <Route path="/abc">
                    <Abc />
                </Route> */}
            </Switch>

        </BrowserRouter>
    )
}

export default AppRouting