import * as React from "react";
import "./TextEditor.scss";
import { EditorState, Editor, ContentState, RichUtils, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "draft-js/dist/Draft.css";
import { CButton } from "@coreui/react";

//components
import { BlockStyleSelector } from "./messages";

//services
import {
  htmlToDeviceFormat,
  convertToStateFromHTML,
} from "../utils/formatConverters";
import { useState, useEffect } from "react";

interface Props {
  className?: string;
  onChangeText?: (e: any) => void;
  defaultValue?: string;
  isEmail?: boolean;
  placeHolder?: string;
}

const TextEditor: React.FC<Props> = ({
  className,
  onChangeText,
  defaultValue,
  isEmail,
  placeHolder,
}) => {
  const MAX_LENGTH = isEmail ? 300 : 144;
  const [editorState, setEditorState] = React.useState<EditorState>(
    defaultValue
      ? EditorState.createWithContent(convertToStateFromHTML(defaultValue))
      : EditorState.createEmpty()
  );
  const [plainTextLength, setPlainTextLength] = React.useState(0);

  useEffect(() => {
    if (defaultValue) {
      setEditorState(EditorState.createWithContent(convertToStateFromHTML(defaultValue)));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [defaultValue]);

  useEffect(() => {
    const html = stateToHTML(editorState.getCurrentContent());
    if (onChangeText) {
      const formattedHtml = isEmail ? html : htmlToDeviceFormat(html);
      onChangeText(formattedHtml);
    }
    setPlainTextLength(editorState.getCurrentContent().getPlainText("").length);
  }, [editorState, onChangeText, isEmail]);

  const _handleChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  const _handleBeforeInput = (): "handled" | "not-handled" => {
    const currentContentLength = editorState.getCurrentContent().getPlainText("").length;
    return currentContentLength >= MAX_LENGTH ? "handled" : "not-handled";
  };

  const _handlePastedText = (pastedText: string): "handled" | "not-handled" => {
    const currentContentLength = editorState.getCurrentContent().getPlainText("").length;
    return currentContentLength + pastedText.length > MAX_LENGTH ? "handled" : "not-handled";
  };

  const handleReturn = (): "handled" | "not-handled" => {
    const currentContentLength = editorState.getCurrentContent().getPlainText("").length;
    if (currentContentLength >= MAX_LENGTH) {
      return "handled"; // Blocks the Enter key when the text limit is reached
    }
    return "not-handled"; // Allows Enter key otherwise
  };

  function toggleInlineStyle(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    const style = event.currentTarget.getAttribute("data-style");
    if (style) {
      setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    }
  }

  function toggleBlockType(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    const block = event.currentTarget.getAttribute("data-block");
    if (block) {
      setEditorState(RichUtils.toggleBlockType(editorState, block));
    }
  }

  return (
    <div className={`text-editor-container ${className}`}>
      <div className="toolbar-style-text-editor">
        {isEmail && (
          <div className="d-flex mb-1">
            <CButton
              color="dark"
              className="style-button m-0 me-2"
              onClick={toggleInlineStyle}
              data-style="BOLD"
            >
              <strong>B</strong>
            </CButton>
            <CButton
              color="dark"
              className="style-button m-0 me-2"
              onClick={toggleInlineStyle}
              data-style="ITALIC"
            >
              <em>IT</em>
            </CButton>
            <CButton
              color="dark"
              className="style-button m-0 me-2"
              onClick={toggleInlineStyle}
              data-style="UNDERLINE"
            >
              <u>U</u>
            </CButton>
            <BlockStyleSelector toggleBlockType={toggleBlockType} />
          </div>
        )}
        <div />
        <p className="py-1">{`${plainTextLength}/${MAX_LENGTH}`}</p>
      </div>
      <Editor
        editorState={editorState}
        onChange={_handleChange}
        handleBeforeInput={_handleBeforeInput}
        handlePastedText={_handlePastedText}
        handleReturn={handleReturn}
        placeholder={placeHolder}
      />
    </div>
  );
};

export default TextEditor;
