import * as React from "react";
import "../../views/Staff.scss";

import { cilPencil, cilTrash, cilUser } from "@coreui/icons";
import { IconButton } from "../Atomics";
import { NewParents, Roles } from "../types";
import EditEmergency from "./EditEmergency";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveRolesUser } from "../../redux/reducers/userReducer";
import usePermission from "../../hooks/usePermission";
import { convertUTCtoLocale } from "../../utils/formatConverters";
import moment from "moment";
import EditEmergencyRelation from "./EditEmergencyRelation";
//service

interface ParentsRowProps {
  parent: NewParents;
  removeUser: (id: number) => void;
  refreshParents: () => void;
}

const NewParentsRow: React.FC<ParentsRowProps> = ({
  parent,
  removeUser,
  refreshParents,
}) => {
  const { checkPermission } = usePermission()
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [visibleEditParent, setVisibleEditParent] = React.useState(false);
  const localeDate = convertUTCtoLocale(parent.createdAt);
  function onPressAdd() {
    setVisibleEditParent(!visibleEditParent);
  }
  return (
    <>

      <div className="d-flex py-3 user-information-container justify-content-around px-2 table-size">

        <div className="section-width-parents-custom d-flex justify-content-start ">
          <p className="text-light word-break">
            {parent?.user?.student_id}
          </p>
        </div>
        <div className="section-width-parents-custom d-flex justify-content-start ">
          <p className="text-light word-break">{parent?.user?.firstName} {parent?.user?.lastName}</p>
        </div>

        <div className="section-width-parents-custom d-flex justify-content-start ">
          <p className="text-light word-break">{parent?.first_name} {parent?.last_name}</p>
        </div>

        <div className="section-width-parents-custom d-flex justify-content-start ">
          <p className="text-light word-break">{parent?.email || "-"} </p>
        </div>

        <div className="section-width-parents-custom d-flex justify-content-start ">
          <p className="text-light word-break">{parent?.phone}</p>
        </div>

        <div className="section-width-parents-custom d-flex justify-content-start ">
          <p className="text-light word-break">{parent?.relation?.name || "-"}</p>
        </div>
        <div className="section-width-parents-custom d-flex justify-content-start ">
          <p className="text-light word-break">{parent?.is_current == '1' ? "Active" : "Not Active"}</p>
        </div>


        <div className="d-none w-auto d-flex justify-content-end align-items-center">
          <div className="margin-icon">
            <span
              className="badge bg-primary text-white"
              role="button"
              onClick={() => onPressAdd()}
            >
              View
            </span>
          </div>
          <div className="d-none">
            <IconButton
              icon={cilTrash}
              size="lg"
              className="ms-1"
              onClick={() => removeUser(parent.id)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewParentsRow;
