import * as React from "react";
import "../staff/NewStaff.scss";
import "./NewParent.scss"
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";

//components
import {
  Modal,
  Input,
  Button,
  Spinner,
  IconButton,
} from "../Atomics";
import { cilX } from "@coreui/icons";
import { saveNewParents, saveNewParentsMessage, saveParents } from "../../services/parentsService";
import { createActivityReq } from "../../services/activityLogService";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { getEmergencyRelations, getStudentId } from "../../services/newParentService";
import { EmergencyRelation, User } from "../types";

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshParents: () => void;
  setRefreshPage: (e: any) => void;
  refreshPage: boolean;
}

const NewParentMessage: React.FC<Props> = ({ visible, closeModal, refreshParents, setRefreshPage, refreshPage }) => {

  const [message, setMessage] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");
  const [addParentLoading, setAddParentLoading] = React.useState<boolean>(false);


  async function addNewParentMessage(
    title: string,
    message: string
  ): Promise<void> {
    setAddParentLoading(true);
    try {
      if (message === "" || message === null) {
        toast.error("Message cannot be empty")
        setAddParentLoading(false);
      } else {
        await saveNewParentsMessage(title, message);
        setRefreshPage(!refreshPage);
        toast.success("Message Send Successfully ");
        setAddParentLoading(false);
        onCloseModal();
      }
    } catch (e) {
      setAddParentLoading(false);
      HandleUIError(e);
    }
  }

  const handleTitle = (e: any) => {
    setTitle(e.target.value)
  }

  console.log("first  title", title)

  // React.useEffect(() => {
  //   reFetchStudentId("00-006");
  // }, []);

  function onCloseModal() {
    setMessage("")
    closeModal();
  }



  return (
    <Modal visible={visible} >
      <div className="new-staff-container">
        <div className="new-staff-header">
          <p className="text-light py-2 ps-4 fs-5">Add New Message</p>
          <IconButton icon={cilX} className="me-3" onClick={closeModal} />
        </div>

        <div className="parents-container">

          <div className="d-flex align-items-center mt-3 ms-3">
            <p className="text-light fs-5 m-0 me-2">Title:</p>
            <Input
              className="w-100 me-3 text-light"
              value={title}
              type="text"
              onChange={handleTitle}
            />
          </div>


          <p className="text-light fs-5 ms-3 pt-3">
            Message
          </p>

          <div className="px-3 mb-4">
            <textarea
              rows={5}
              className="form-control shadow-none"
              style={{
                backgroundColor: '#24242C',
                border: '2px solid #44454a',
                color: '#51fffe',
              }}
              value={message}
              maxLength={300}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="p-1 rounded-2 px-2" style={{ border: '2px solid #44454a' }}>
              <p className="py-1 text-end">{`${message?.length}/300`}</p>
            </div>
          </div>


          <Button
            disabled={addParentLoading}
            className="mt-7 button-add px-4 mb-3"
            onClick={() => addNewParentMessage(title,message)}
          >
            {!addParentLoading ? "Submit" : <Spinner />}
          </Button>
        </div>
      </div >
    </Modal >
  );
};

export default NewParentMessage;
