import axiosConfig from "./axiosConfig";
import { AxiosResponse, AxiosError } from "axios";
import { store } from "../redux/store";
import { saveUser, removeUser } from "../redux/reducers/userReducer";
import ApiError from "../utils/ApiError";
import { AuthenticateData, AuthenticateResponse, OrganizationInformation } from "./types";
// import { getFcmToken, deleteFcmToken } from "./pushNotificationService";
import { unregisterFcmToken } from "../services/selfService";
import { getOrganizationInfo, getUserFullInfo } from "./loggedUserService";
import toast from "react-hot-toast";
import {
  clearOrganization,
  saveOrganization,
} from "../redux/reducers/organizationReducer";

async function adminRequest() {
  const res = await getUserFullInfo();
  if (res.isAdmin === true && res.organizationId !== null) {
    return true;
  } else {
    return false;
  }
}
export async function authenticate(
  loginData: AuthenticateData
): Promise<AuthenticateResponse> {
  try {
    const response = await axiosConfig.post<AxiosResponse>(
      "/authenticate",
      loginData
    );
    const authenticateData: AuthenticateResponse = response.data
      .data as AuthenticateResponse;
    const userData = {
      user: {
        id: authenticateData.id,
        email: authenticateData.email,
        isAdmin: null,
      },
      tokens: {
        access: authenticateData.tokens.access,
        refresh: authenticateData.tokens.refresh,
      },
      rolesUser: {
        id: 0,
      },
      permissions: authenticateData.permissions,
    };
    store.dispatch(saveUser(userData));
    const isAdmin = await adminRequest();
    if (isAdmin) {
      // await getFcmToken();
      await getUserFullInfo().catch((e) => console.warn(e));
    } else {
      signOut();
      toast.error(
        "You are not an admin or you still do not have an organization"
      );
    }
    const res = await getOrganizationInfo();
    store.dispatch(saveOrganization(res));
    return authenticateData;
  } catch (e: any) {
    return e?.response?.data?.message;
  }
}

export async function signOut(): Promise<void> {
  try {
    // await deleteFcmToken(); //delete fcm token from the firebase
    await unregisterFcmToken(); //delete fcm token from the Luti ddbb
    store.dispatch(removeUser());
    store.dispatch(clearOrganization());
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}


export async function getOrganizationData(
  organizationId: string
): Promise<Array<OrganizationInformation>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `api/auth/organizations?title=${organizationId}`
    );
    return response.data.data as Array<OrganizationInformation>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
